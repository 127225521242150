<template>
<div class="card card-primary mt-3">
    <div class="card-header" style="height: 52px">
        <h5 class="text-center">Thông tin Khách hàng đăng ký báo giá</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="col-md-12" style="padding-left: 40px;">
            <div class="row" style="margin-bottom: 15px">
                <div class="col-md-4"><b>Tên khách hàng:</b></div>
                <div class="col-md-8">{{ insuranceProducts.name }}</div>
            </div>
            <div class="row" style="margin-bottom: 15px">
                <div class="col-md-4"><b>Số ĐT:</b></div>
                <div class="col-md-8">{{ insuranceProducts.phone }}</div>
            </div>
            <div class="row" style="margin-bottom: 15px">
                <div class="col-md-4"><b>Email:</b></div>
                <div class="col-md-8">{{ insuranceProducts.email }}</div>
            </div>
            <div class="row" style="margin-bottom: 15px">
                <div class="col-md-4"><b>Gói bảo hiểm:</b></div>
                <div class="col-md-8">{{ insuranceProducts.insurancePackage }}</div>
            </div>
            <div class="row" style="margin-bottom: 15px">
                <div class="col-md-4"><b>Phí ốm bệnh:</b></div>
                <div class="col-md-8">{{ insuranceProducts.priceOmBenh }}</div>
            </div>
            <div class="row" style="margin-bottom: 15px">
                <div class="col-md-4"><b>Phí tai nạn:</b></div>
                <div class="col-md-8">{{ insuranceProducts.priceTaiNan }}</div>
            </div>
            <div class="row" style="margin-bottom: 15px">
                <div class="col-md-4"><b>Phí y tế:</b></div>
                <div class="col-md-8">{{ insuranceProducts.priceYTe }}</div>
            </div>
            <div class="row" style="margin-bottom: 15px">
                <div class="col-md-4"><b>Phí nội trú:</b></div>
                <div class="col-md-8">{{ insuranceProducts.priceNoiTru }}</div>
            </div>
            <div class="row" style="margin-bottom: 15px">
                <div class="col-md-4"><b>Phí ngoại trú:</b></div>
                <div class="col-md-8">{{ insuranceProducts.priceNgoaiTru }}</div>
            </div>
            <div class="row" style="margin-bottom: 15px">
                <div class="col-md-4"><b>Ghi chú:</b></div>
                <div class="col-md-8">{{ insuranceProducts.note }}</div>
            </div>
            <div class="row" style="margin-bottom: 15px">
                <div class="col-md-4"><b>Số người:</b></div>
                <div class="col-md-8">{{ insuranceProducts.quantity }}</div>
            </div>
            <div class="row" style="margin-bottom: 15px">
                <div class="col-md-4"><b>Tổng tiền:</b></div>
                <div class="col-md-8">{{ formatPrice(insuranceProducts.totalPrice) }} VNĐ</div>
            </div>
            <div class="row" style="margin-bottom: 15px">
                <div class="col-md-4"><b>Ngày đăng ký tư vấn:</b></div>
                <div class="col-md-8">{{ format_date(insuranceProducts.createdDate) }}</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import moment from 'moment'
export default {
    props: ['insuranceProduct'],

    data() {

        return {
            insuranceProducts: this.insuranceProduct,
            url: null,
            message: "",
            insuranceProductss: null,
        }
    },
    methods: {
        formatPrice(value) {
            return new Intl.NumberFormat('en-US').format(value);
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format('DD-MM-YYYY - h:mm:ss a')
            }
        },
        format_birthday(value) {
            if (value) {
                return moment(String(value)).format('DD-MM-YYYY')
            }
        },
    }

}
</script>
