<template>
<div class="card card-primary mt-3">
    <div class="card-header" style="height: 52px">
        <h5 class="text-center">Thông tin Khách hàng đăng ký</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="col-md-12" style="padding-left: 40px;">
            <div class="row" style="margin-bottom: 15px">
                <div class="col-md-4"><b>Tên khách hàng:</b></div>
                <div class="col-md-8">{{ userlandings.name }}</div>
            </div>
            <div class="row" style="margin-bottom: 15px">
                <div class="col-md-4"><b>Số ĐT:</b></div>
                <div class="col-md-8">{{ userlandings.phone }}</div>
            </div>
            <div class="row" style="margin-bottom: 15px">
                <div class="col-md-4"><b>Loại hình bảo hiểm:</b></div>
                <div class="col-md-8">{{ userlandings.categoryInsurance }}</div>
            </div>
            <div class="row" style="margin-bottom: 15px">
                <div class="col-md-4"><b>Sản phẩm bảo hiểm:</b></div>
                <div class="col-md-8">{{ userlandings.productInsurance }}</div>
            </div>
            <div class="row" style="margin-bottom: 15px">
                <div class="col-md-4"><b>Loại hình tư vấn:</b></div>
                <div class="col-md-8">{{ userlandings.consulting }}</div>
            </div>
            <div class="row" style="margin-bottom: 15px">
                <div class="col-md-4"><b>Hình thức tư vấn:</b></div>
                <div class="col-md-8">{{ userlandings.typeContact }}</div>
            </div>
            <div class="row" style="margin-bottom: 15px">
                <div class="col-md-4"><b>Ghi chú:</b></div>
                <div class="col-md-8">{{ userlandings.note }}</div>
            </div>
            <div class="row" style="margin-bottom: 15px">
                <div class="col-md-4"><b>Ngày đăng ký tư vấn:</b></div>
                <div class="col-md-8">{{ format_date(userlandings.createdDate) }}</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import UserLandingService from '@/services/UserLandingService';
import moment from 'moment'
export default {
    props: ['userlanding'],

    data() {

        return {
            userlandings: this.userlanding,
            url: null,
            message: "",
            userlandingss: null,
        }

    },
    mounted() {
        UserLandingService.get()
            .then((res) => {
                this.userlandingss = res.data;
            });
        UserLandingService.get(this.userlanding.id).then((res) => {
            this.userlandingss = res.data;
        })
    },
    methods: {
        format_date(value) {
            if (value) {
                return moment(String(value)).format('DD-MM-YYYY - h:mm:ss a')
            }
        },
    }

}
</script>
