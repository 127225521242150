<template>
<div class="card card-warning mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Chỉnh sửa điều kiện</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitEditForm" v-if="conditions">
                <input type="hidden" v-model="conditions.id">
                <div class="form-group row mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Danh mục</label>
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <select class="form-control basic" name="" id="categoryConditionId" v-model="conditions.categoryConditionId">
                            <option value="">Choose</option>
                            <option v-for="item in catecondition" :key="item.id" :selected="conditions.categoryConditionId === item.id" v-bind:value="item.id">
                                {{item.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-group row mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label col-form-label">Gói bảo hiểm</label>
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <p>Tìm gói bảo hiểm</p>
                        <input type="text" class="form-control mb-2" v-model="query" placeholder="">

                        <select class="form-control basic" name="" id="itemId" v-model="conditions.itemId">
                            <option value="">Chọn</option>
                            <option v-for="item in conditionFilte" :key="item.id" :selected="conditions.itemId === item.id" v-bind:value="item.id">
                                {{item.productName+ ' '+ item.insuranceName+ ' ' + item.valueAttributeName }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group row mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Giá</label>
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <input type="text" class="form-control" id="price" placeholder="" v-model="conditions.price" :class="{ error: priceError.status, success: priceSuccess.status }">
                        <p class="text-error" v-if="priceError.status">{{ priceError.text }}</p>
                        <p class="success-text" v-if="priceSuccess.status">{{ priceSuccess.text }}
                        </p>
                    </div>
                </div>
                <fieldset class="form-group mb-4">
                    <div class="row">
                        <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">Trạng thái</label>
                        <div class="col-xl-10 col-lg-9 col-sm-10">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" v-model="conditions.status" :value="true" :checked="conditions.status === true" id="status" style="width: 16px;height: 16px;" />
                                <label class="form-check-label" for="flexCheckDefault"> Hoạt động
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" v-model="conditions.status" :value="false" :checked="conditions.status === false" id="status" style="width: 16px;height: 16px;" />
                                <label class="form-check-label" for="flexCheckChecked"> Không hoạt động </label>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Xác nhận sửa</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import ConditionService from '@/services/ConditionService';
import CategoryConditionService from '@/services/CategoryConditionService';
import ItemService from '@/services/ItemService';
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    props: ['condition'],

    data() {

        return {
            conditions: this.condition,
            url: null,
            a: null,
            ishowImage: false,
            news: null,
            query: "",
            catecondition: null,
            message: "",

            SameError: {
                text: "",
                status: false,
            },
            SameSuccess: {
                text: "",
                status: false,
            },
            priceError: {
                text: "",
                status: false,
            },
            priceSuccess: {
                text: "",
                status: false,
            },

        }

    },
    mounted() {
        ConditionService.getAll().then((res) => {
            this.a = res.data
        })
        ItemService.getAll()
            .then((res) => {
                this.news = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            });
        CategoryConditionService.getAll()
            .then((res) => {
                this.catecondition = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            });

    },
    computed: {
        conditionFilte() {
            if (this.query) {
                return this.news.filter((condition) => {
                    return (
                        (condition.productName + ' ' + condition.insuranceName + ' ' + condition.valueAttributeName)
                        .toLowerCase()
                        .indexOf(this.query.toLowerCase()) != -1
                    )
                })
            } else {
                return this.news;
            }
        },
    },
    methods: {
        onSubmitEditForm() {
            var itemSame;
            var categoryConditionSame;
            for (let i = 0; i < this.a.length; i++) {
                const element = this.a[i];
                if (this.condition.itemId == element.itemId && this.condition.categoryConditionId == element.categoryConditionId) {
                    itemSame = element.itemId;
                    categoryConditionSame = element.categoryConditionId;
                    break;
                }
            }

            if (this.conditions.price.length == 0) {
                this.priceError = {
                    text: "Giá không được để trống!",
                    status: true
                }
                this.priceSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.conditions.price.length > 0) {
                this.priceSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.priceError = {
                    text: "",
                    status: false
                }
            } else {
                this.priceSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.condition.itemId == itemSame && this.condition.categoryConditionId == categoryConditionSame) {
                createToast({
                    title: 'Đã có quyền lợi này trong danh sách',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
                this.SameError = {
                    text: "Same",
                    status: true
                }
                this.SameSuccess = {
                    text: "",
                    status: false
                }
            } else {
                this.SameSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.priceSuccess.status == true && this.SameSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    ConditionService.update(this.conditions)
                        .then((res) => {
                            console.log(res);
                            createToast({
                                title: 'Chỉnh sửa thành công',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                            createToast({
                                title: 'Đã xảy ra lỗi',
                            }, {
                                position: 'top-right',
                                type: 'danger',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .finally(() => {
                            //Perform action in always
                        });
                    this.$emit("ShowEditData", this.conditions);

                } else {
                    createToast({
                        title: 'Bạn không có quyền sử dụng chức năng này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Chỉnh sửa thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },
    }

}
</script>
