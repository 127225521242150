import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class InsuranceProductDNService {
    getAll() {
        return http.get("/InsuranceProductDN",httpOptions);
    }
    get(id) {
        return http.get(`/InsuranceProductDN/${id}`,httpOptions);
    }
    getCate(id) {
        return http.get(`/InsuranceProductDN/getcategoryID/${id}`,httpOptions);
    }
    getTrash() {
        return http.get("/InsuranceProductDN/TrashInsuranceProductDN",httpOptions);
    }
    create(data) {
        return http.post("/InsuranceProductDN", data,httpOptions);
    }
    update(data) {
        return http.post(`/InsuranceProductDN/PutInsuranceProductDN`, data,httpOptions);
    }
    temporaryDelete(data) {
        return http.post(`/InsuranceProductDN/TemporaryDelete`, data,httpOptions);
    }
    repeat(data) {
        return http.post(`/InsuranceProductDN/RepeatInsuranceProductDN`, data,httpOptions);
    }
    delete(id) {
            return http.delete(`/InsuranceProductDN/${id}`);
        }
        //   deleteAll() {
        //     return http.delete(`/tutorials`);
        //   }
        //   findByTitle(title) {
        //     return http.get(`/tutorials?title=${title}`);
        //   }
}
export default new InsuranceProductDNService();