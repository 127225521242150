<template>
<div class="card card-warning mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Chỉnh sửa danh mục dịch vụ</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitEditForm" v-if="services">
                <input type="hidden" v-model="services.id">
                <div class="form-group row mb-4">
                    <label for="exampleFormControlInput1" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mã DM dịch vụ</label>
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <input type="text" class="form-control" id="code" placeholder="" v-model="services.code" :class="{ error: codeError.status, success: codeSuccess.status }" />
                        <p class="text-error" v-if="codeError.status">{{ codeError.text }}</p>
                        <p class="success-text" v-if="codeSuccess.status">{{ codeSuccess.text }}
                        </p>
                    </div>
                </div>
                <div class="form-group row mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Tên</label>
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <input type="text" class="form-control" id="name" placeholder="" v-model="services.name" :class="{ error: nameError.status, success: nameSuccess.status }">
                        <p class="text-error" v-if="nameError.status">{{ nameError.text }}</p>
                        <p class="success-text" v-if="nameSuccess.status">{{ nameSuccess.text }}
                        </p>
                    </div>
                </div>

                <fieldset class="form-group mb-4">
                    <div class="row">
                        <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">Trạng thái</label>
                        <div class="col-xl-10 col-lg-9 col-sm-10">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" v-model="services.status" :value="true" :checked="services.status === true" id="status" style="width: 16px;height: 16px;" />
                                <label class="form-check-label" for="flexCheckDefault"> Hoạt động
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" v-model="services.status" :value="false" :checked="services.status === false" id="status" style="width: 16px;height: 16px;" />
                                <label class="form-check-label" for="flexCheckChecked"> Không hoạt động </label>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <div class="form-group mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Tiêu đề Seo</label>
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <input type="text" class="form-control" id="titleSeo" placeholder="" v-model="services.titleSeo" :class="{ error: titleSeoError.status, success: titleSeoSuccess.status }">
                        <p class="text-error" v-if="titleSeoError.status">{{ titleSeoError.text }}</p>
                        <p class="success-text" v-if="titleSeoSuccess.status">{{ titleSeoSuccess.text }}
                        </p>
                    </div>
                </div>
                <div class="form-group mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Key Words</label>
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <input type="text" class="form-control" id="metaKeyWords" placeholder="" v-model="services.metaKeyWords" :class="{ error: metaKeyWordsError.status, success: metaKeyWordsSuccess.status }">
                        <p class="text-error" v-if="metaKeyWordsError.status">{{ metaKeyWordsError.text }}</p>
                        <p class="success-text" v-if="metaKeyWordsSuccess.status">{{ metaKeyWordsSuccess.text }}
                        </p>
                    </div>
                </div>
                <div class="form-group mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mô tả Seo</label>
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <textarea name="" id="metaDescription" style="width: 100%;" cols="30" rows="10" v-model="services.metaDescription" :class="{ error: metaDescriptionError.status, success: metaDescriptionSuccess.status }"></textarea>
                        <p class="text-error" v-if="metaDescriptionError.status">{{ metaDescriptionError.text }}</p>
                        <p class="success-text" v-if="metaDescriptionSuccess.status">{{ metaDescriptionSuccess.text }}
                        </p>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Xác nhận sửa</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import ServiceService from '@/services/ServiceService';
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    props: ['service'],

    data() {

        return {
            services: this.service,
            url: null,
            ishowImage: false,
            message: "",
            codeError: {
                text: "",
                status: false,
            },
            codeSuccess: {
                text: "",
                status: false,
            },
            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            },
            titleSeoError: {
                text: "",
                status: false,
            },
            titleSeoSuccess: {
                text: "",
                status: false,
            },

            metaKeyWordsError: {
                text: "",
                status: false,
            },
            metaKeyWordsSuccess: {
                text: "",
                status: false,
            },

            metaDescriptionError: {
                text: "",
                status: false,
            },
            metaDescriptionSuccess: {
                text: "",
                status: false,
            },
        }

    },

    methods: {
        onSubmitEditForm() {

            if (this.services.code.length == 0) {
                this.codeError = {
                    text: "Mã không được để trống",
                    status: true
                }
                this.codeSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.services.code.length < 4) {
                this.codeError = {
                    text: "Mã phải lớn hơn 4 kí tự",
                    status: true
                }
                this.codeSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.services.code.length >= 4) {
                this.codeSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.codeError = {
                    text: "",
                    status: false
                }
            } else {
                this.codeError = {
                    text: "",
                    status: false
                }
            }

            if (this.services.name.length == 0) {
                this.nameError = {
                    text: "Tên không được để trống!",
                    status: true
                }
                this.nameSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.services.name.length > 0) {
                this.nameSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.nameError = {
                    text: "",
                    status: false
                }
            } else {
                this.nameSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.codeSuccess.status == true && this.nameSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    ServiceService.update(this.services)
                        .then((res) => {
                            console.log(res);
                            this.$emit("ShowEditData", this.services);
                            createToast({
                                title: 'Chỉnh sửa thành công',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                            createToast({
                                title: 'Đã xảy ra lỗi',
                            }, {
                                position: 'top-right',
                                type: 'danger',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .finally(() => {
                            //Perform action in always
                        });
                    this.$emit("ShowEditData", this.services);

                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Chỉnh sửa thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },
    }

}
</script>
