<template>
<div class="card card-danger mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Danh sách khách hàng đã xóa</h5>
    </div>
    <div class="input-group mt-3 col-md-6">
        <div class="input-group-prepend">
            <span class="input-group-text">Tìm Kiếm</span>
        </div>
        <input type="text" v-model="query" class="form-control" placeholder="Tìm Kiếm">
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th class="text-center">Mã</th>
                            <th>Họ tên</th>
                            <th>Số điện thoại</th>
                            <th>Bảo hiểm</th>
                            <th class="text-center">Chức năng</th>
                        </tr>
                    </thead>
                    <tbody v-if="query">
                        <tr v-for="item in userlandingFilte" :key="item.id">
                            <td class="text-center">{{ item.code }}</td>
                            <td>{{ item.name }}</td>
                            <td>
                                {{ item.phone }}
                            </td>
                            <td>{{ item.productInsurance }}</td>

                            <td class="text-center">
                                <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-warning mr-2" data-placement="top" title="Phục hồi" data-original-title="Edit" style="padding: 12px" v-on:click="onRepeat(item)"><i class="fa-solid fa-recycle"></i></a>
                                <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-danger" v-on:click.stop.prevent="onDelete(item)" data-placement="top" style="padding: 12px" title="Xóa" data-original-title="Delete"><i class="fa-solid fa-trash fa-lg"></i></a>

                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr v-for="item in paginated" :key="item.id">
                            <td class="text-center">{{ item.code }}</td>
                            <td>{{ item.name }}</td>
                            <td>
                                {{ item.phone }}
                            </td>
                            <td>{{ item.productInsurance }}</td>

                            <td class="text-center">
                                <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-warning mr-2" data-placement="top" title="Phục hồi" data-original-title="Edit" style="padding: 12px" v-on:click="onRepeat(item)"><i class="fa-solid fa-recycle"></i></a>
                                <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-danger" v-on:click.stop.prevent="onDelete(item)" data-placement="top" style="padding: 12px" title="Xóa" data-original-title="Delete"><i class="fa-solid fa-trash fa-lg"></i></a>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="column is-12">
                <nav class="pagination is-right" role="navigation" aria-label="pagination">
                    <ul class="pagination pagination-sm m-0 float-right" v-if="totalPaginate > 1">
                        <li class="page-item"><a class="page-link" @click="prev" href="javascript:void(0)">«</a></li>
                        <li class="page-item" v-for="item in totalPaginate" :key="item">
                            <span v-on:click.prevent="onCurrent(item)" v-bind:class="{ isActive: (item === current), 'text-dark': isActive == false }" class="btn-paginate pagination-link go-to has-text-orange page-link" style="border-radius: 0;margin: 0;" aria-label="Goto page 1">{{ item }}</span>
                        </li>
                        <li class="page-item"><a class="page-link" @click="next" href="javascript:void(0)">»</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>
</template>

<style>
.pagination-list {
    list-style: none;
    display: flex;
}

.btn-paginate {
    margin-inline: 5px;
    cursor: pointer;
    border-style: groove;
    border-radius: 100%;
    text-align: center;
    padding: 10%;
    color: #000;

}

.isActive {
    background-color: #80b500;
    color: #ffff;
}
</style>

<script>
import UserLandingService from "@/services/UserLandingService";
import "vue-awesome-paginate/dist/style.css";
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    name: "Index",
    components: {},
    data() {
        return {
            userlanding: null,
            showEdit: null,
            isShowEdit: false,
            isShowAdd: false,
            isShowTrash: false,
            query: "",
            current: 1,
            pageSize: 5,
            isActive: false,
            trash: this.cate,
        }
    },
    created() {
        UserLandingService.getTrash()
            .then((res) => {
                this.userlanding = res.data;
                console.log(res);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                //Perform action in always
            })

    },
    computed: {
        resultCount() {
            return this.userlanding && this.userlanding.length
        },
        userlandingFilte() {
            if (this.query) {
                return this.userlanding.filter((userlanding) => {
                    return (
                        userlanding.name
                        .toLowerCase()
                        .indexOf(this.query.toLowerCase()) != -1
                    )
                })
            } else {
                return this.userlanding;
            }

        },
        indexStart() {
            return (this.current - 1) * this.pageSize;
        },
        indexEnd() {
            return this.indexStart + this.pageSize;
        },
        totalPaginate() {
            if ((this.resultCount % 5) == 0) {
                return Math.floor(this.resultCount / 5);
            } else {
                return Math.floor(this.resultCount / 5) + 1;
            }
        },
        paginated() {
            console.log(this.resultCount);
            if (this.resultCount > 5) {
                return this.userlanding.slice(this.indexStart, this.indexEnd, this.totalPaginate);
            } else {
                return this.userlanding;
            }
        }

    },
    methods: {
        onCurrent(item) {

            this.isActive = true
            return this.current = item;
        },

        prev() {
            this.current--;
            if (this.current == 0) {
                return this.current = 1;
            }
        },
        next() {
            this.current++;
            if (this.current > this.totalPaginate) {
                return this.current = this.totalPaginate;
            }
        },
        onRepeat(item) {
            createToast({
                title: 'Phục hồi thành công',
            }, {
                position: 'top-right',
                type: 'success',
                transition: 'zoom',
                showIcon: 'true',
                showCloseButton: 'false',
            })
            UserLandingService.repeat(item)
                .then(response => {
                    console.log(response);
                    this.userlanding.splice(this.userlanding.findIndex(e => e.id == item.id), 1).push(response.data);
                    this.$emit("ShowDeleteData", item);
                })
                .catch(function (error) {
                    console.log(error)
                })

        },
        onDelete(item) {
            let login = JSON.parse(sessionStorage.getItem("user"));
            if (login.role == 1 || login.role == 2) {
                if (confirm("Are you sure you want to delete " + item.code)) {
                    createToast({
                        title: 'Xóa thành công',
                    }, {
                        position: 'top-right',
                        type: 'success',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                    console.log(item.id);
                    UserLandingService.delete(item.id)
                        .then(response => {
                            console.log(response);
                            this.userlanding.splice(this.userlanding.findIndex(e => e.id == item.id), 1).push(response.data);
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                }
            } else {
                alert("Bạn không có quyền sử dụng chức năng này");
                createToast({
                    title: 'Bạn không có quyền này',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }

        }
    }

}
</script>
