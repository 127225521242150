<template>
<div class="card-header">
    <h5>Quản lý Danh mục dịch vụ</h5>
</div>
<!--  BEGIN CONTENT AREA  -->

<div class="card-body">
    <div class="layout-px-spacing">

        <div class="row layout-top-spacing">
            <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">

                <div class="statbox widget box box-shadow" v-if="isShowEdit == false && isShowAdd == false">
                    <div class="row" style="display: flex;">
                        <div class="col-md-6">
                            <a v-on:click.prevent="onAdd" class="btn btn-success mb-3">Thêm Mới +</a>

                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Tìm Kiếm</span>
                                </div>
                                <input type="text" v-model="query" class="form-control" placeholder="Tìm Kiếm">
                            </div>
                        </div>
                    </div>
                    <div class="widget-content widget-content-area">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th class="text-center">Mã DM tin tức</th>
                                        <th>Tên</th>
                                        <th>Trạng thái</th>
                                        <th class="text-center">Chức năng</th>
                                    </tr>
                                </thead>
                                <tbody v-if="query">
                                    <tr v-for="item in serviceFilte" :key="item.id">
                                        <td class="text-center">{{ item.code }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>
                                            <p class="text-success">
                                                <span v-if="item.status">Hoạt động</span>
                                                <span v-if="!item.status">Không hoạt động</span>
                                            </p>
                                        </td>
                                        <td class="text-center">
                                            <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-warning mr-2" data-placement="top" title="Chỉnh sửa" data-original-title="Edit" style="padding: 12px" v-on:click="onEdit(item)"><i class="fa-regular fa-pen-to-square fa-lg"></i></a>
                                            <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-danger" v-on:click.stop.prevent="onDelete(item)" data-placement="top" style="padding: 12px" title="Xóa" data-original-title="Delete"><i class="fa-solid fa-trash fa-lg"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-for="item in paginated" :key="item.id">
                                        <td class="text-center">{{ item.code }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>
                                            <p class="text-success">
                                                <span v-if="item.status">Hoạt động</span>
                                                <span v-if="!item.status">Không hoạt động</span>
                                            </p>
                                        </td>
                                        <td class="text-center">
                                            <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-warning mr-2" data-placement="top" title="Chỉnh sửa" data-original-title="Edit" style="padding: 12px" v-on:click="onEdit(item)"><i class="fa-regular fa-pen-to-square fa-lg"></i></a>
                                            <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-danger" v-on:click.stop.prevent="onDelete(item)" data-placement="top" style="padding: 12px" title="Xóa" data-original-title="Delete"><i class="fa-solid fa-trash fa-lg"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="column is-12">
                            <div class="example-six">
                                <vue-awesome-paginate :total-items="resultCount" v-model="currentPage" :items-per-page="pageSize" :max-pages-shown="5" v-on:click="onClickHandler">
                                    <template #prev-button>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="12" height="12" viewBox="0 0 24 24" style="vertical-align: inherit;">
                                                <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                                            </svg>
                                        </span>
                                    </template>

                                    <template #next-button>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="12" height="12" viewBox="0 0 24 24" style="vertical-align: inherit;">
                                                <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                                            </svg>
                                        </span>
                                    </template>
                                </vue-awesome-paginate>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="" v-if="isShowEdit == true || isShowAdd == true" v-on:click.prevent="back_to"><svg xmlns="http://www.w3.org/2000/svg" width="16" style="width: 32px;
                            height: 32px;" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                    </svg></a>
                <ServiceEdit :service="showEdit" v-if="isShowEdit == true" @ShowEditData="getEdit($event)" />
                <ServiceAdd v-if="isShowAdd == true" @ShowData="getData($event)" />
            </div>
        </div>
    </div>
</div>
</template>

<style>
.example-six .pagination-container {
    column-gap: 10px;
    align-items: center;
}

.example-six .paginate-buttons {
    height: 35px;
    width: 35px;
    cursor: pointer;
    border-radius: 4px;
    background-color: transparent;
    border: none;
    color: black;
    border: 1px solid;
}

.example-six .back-button,
.example-six .next-button {
    background-color: black;
    color: white;
    border-radius: 8px;
    height: 40px;
    width: 40px;
}

.example-six .active-page {
    background-color: #20c997;
    color: white;
}

.example-six .paginate-buttons:hover {
    background-color: #f5f5f5;
}

.example-six .active-page:hover {
    background-color: #20c997;
}

.example-six .back-button svg {
    transform: rotate(180deg) translateY(-2px);
}

.example-six .next-button svg {
    transform: translateY(2px);
}

.example-six .back-button:hover,
.example-six .next-button:hover {
    background-color: rgb(45, 45, 45);
}

.example-six .back-button:active,
.example-six .next-button:active {
    background-color: rgb(85, 85, 85);
}
</style>

<script>
import ServiceEdit from "../Service/edit.vue";
import ServiceAdd from "../Service/add.vue";
import ServiceService from "@/services/ServiceService";
import {
    ref
} from "vue";
import "vue-awesome-paginate/dist/style.css";
import 'mosha-vue-toastify/dist/style.css';
import { createToast } from 'mosha-vue-toastify';
export default {
    name: "Index",
    components: {
        ServiceAdd,
        ServiceEdit
    },
    data() {
        return {
            service: null,
            showEdit: null,
            isShowEdit: false,
            isShowAdd: false,
            query: "",
            current: 1,
            pageSize: 5,
            isActive: false
        }
    },
    created() {
        ServiceService.getAll()
            .then((res) => {
                this.service = res.data;
                console.log(res);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                //Perform action in always
            })

    },
    setup() {
        const currentPage = ref(1);
        return {
            currentPage,
        };
    },
    computed: {
        resultCount() {
            return this.service && this.service.length
        },
        serviceFilte() {
            if (this.query) {
                return this.service.filter((service) => {
                    return (
                        service.name
                        .toLowerCase()
                        .indexOf(this.query.toLowerCase()) != -1
                    )
                })
            } else {
                return this.service;
            }

        },
        indexStart() {
            return (this.currentPage * this.pageSize) - this.pageSize;
        },
        indexEnd() {
            return this.indexStart + this.pageSize;
        },
        paginated() {
            if (this.resultCount > 5) {
                return this.service.slice(this.indexStart, this.indexEnd);
            } else {
                return this.service;
            }
        }

    },
    methods: {
        onClickHandler(page) {
            this.current = page.current;
        },
        onEdit(data) {
            this.showEdit = data;
            this.isShowEdit = true
            console.log(data);
        },
        back_to() {
            this.isShowEdit = false,
                this.isShowAdd = false
        },
        onAdd() {
            this.isShowAdd = true
        },
        getData(data) {
            this.service.push(data);
            console.log(data);
            this.isShowAdd = false;
            this.$forceUpdate();

        },
        getEdit(data) {
            for (let i = 0; i < this.service.length; i++) {
                if (this.service[i].id == data.id) {
                    this.service[i] = data;
                    this.$forceUpdate();
                    break;
                }
            }

            console.log(this.service);
            this.isShowEdit = false;
        },
        onDelete(item) {
            let login = JSON.parse(sessionStorage.getItem("user"));
            if (login.role == 1 || login.role == 2) {
                if (confirm("Are you sure you want to delete " + item.code)) {
                    console.log(item.id);
                    // let login = JSON.parse(sessionStorage.getItem("user"));
                    ServiceService.delete(item.id)
                        .then(response => {
                            console.log(response);
                            this.service.splice(this.service.findIndex(e => e.id == item.id), 1).push(response.data);
                            createToast({
                                title: 'Xóa thành công',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .catch(function (error) {
                            console.log(error)
                            createToast({
                                title: 'Đã có lỗi',
                            }, {
                                position: 'top-right',
                                type: 'danger',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                }
            } else {
                alert("Bạn không có quyền sử dụng chức năng này");
            }
        }
    }

}
</script>
