import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class ProductService {
    getAll() {
        return http.get("/product/ProductHome",httpOptions);
    }
    get(id) {
        return http.get(`/product/${id}`,httpOptions);
    }
    getCate(id) {
        return http.get(`/product/getcategoryID/${id}`,httpOptions);
    }
    getTrash() {
        return http.get("/Product/TrashProduct",httpOptions);
    }
    create(data) {
        return http.post("/product", data,httpOptions);
    }
    update(data) {
        return http.post(`/product/PutProduct`, data,httpOptions);
    }
    temporaryDelete(data) {
        return http.post(`/product/TemporaryDelete`, data,httpOptions);
    }
    repeat(data) {
        return http.post(`/product/RepeatProduct`, data,httpOptions);
    }
    delete(id) {
            return http.delete(`/product/${id}`);
        }
        //   deleteAll() {
        //     return http.delete(`/tutorials`);
        //   }
        //   findByTitle(title) {
        //     return http.get(`/tutorials?title=${title}`);
        //   }
}
export default new ProductService();