<template>
<div class="card card-success mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Thêm mới tài khoản khách hàng</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitForm">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row mb-4">
                            <label for="exampleFormControlInput1" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mã khách hàng</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="code" placeholder="" v-model="account.code" :class="{ error: codeError.status, success: codeSuccess.status }" />
                                <p class="text-error" v-if="codeError.status">{{ codeError.text }}</p>
                                <p class="success-text" v-if="codeSuccess.status">{{ codeSuccess.text }}
                                </p>
                            </div>

                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Họ tên</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="name" placeholder="" v-model="account.name" :class="{ error: nameError.status, success: nameSuccess.status }">
                                <p class="text-error" v-if="nameError.status">{{ nameError.text }}</p>
                                <p class="success-text" v-if="nameSuccess.status">{{ nameSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Email</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="email" placeholder="" v-model="account.email" :class="{ error: emailError.status, success: emailSuccess.status }">
                                <p class="text-error" v-if="emailError.status">{{ emailError.text }}</p>
                                <p class="success-text" v-if="emailSuccess.status">{{ emailSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Số ĐT</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="phone" placeholder="" v-model="account.phone" :class="{ error: phoneError.status, success: phoneSuccess.status }">
                                <p class="text-error" v-if="phoneError.status">{{ phoneError.text }}</p>
                                <p class="success-text" v-if="phoneSuccess.status">{{ phoneSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mật khẩu</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="password" class="form-control" id="password" placeholder="" v-model="account.password" :class="{ error: passwordError.status, success: passwordSuccess.status }">
                                <p class="text-error" v-if="passwordError.status">{{ passwordError.text }}</p>
                                <p class="success-text" v-if="passwordSuccess.status">{{ passwordSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Thêm mới</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import AccountUserService from '@/services/AccountUserService.js';
import AccountService from '@/services/AccountUserService.js';
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    data() {
        return {
            message: "",
            currentImage: undefined,
            accounts: null,
            url: null,
            ID: null,
            account: {
                id: null,
                code: "",
                name: "",
                email: "",
                phone: "",
                password: "",
                status: true
            },
            codeError: {
                text: "",
                status: false,
            },
            codeSuccess: {
                text: "",
                status: false,
            },
            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            },
            emailError: {
                text: "",
                status: false,
            },
            emailSuccess: {
                text: "",
                status: false,
            },
            phoneError: {
                text: "",
                status: false,
            },
            phoneSuccess: {
                text: "",
                status: false,
            },
            passwordError: {
                text: "",
                status: false,
            },
            passwordSuccess: {
                text: "",
                status: false,
            }
        }

    },
    mounted() {
        AccountUserService.getAll()
            .then((res) => {
                this.accounts = res.data;
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                //Perform action in always
            })

    },
    methods: {
        onSubmitForm() {
            if (this.account.code.length == 0) {
                this.codeError = {
                    text: "Mã khách hàng không được để trống",
                    status: true
                }
                this.codeSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.account.code.length < 5) {
                this.codeError = {
                    text: "Mã khách hàng phải lớn hơn 5 kí tự",
                    status: true
                }
                this.codeSuccess = {
                    text: "",
                    status: false
                }
            } else {
                var check_exist_code = true;
                for (var i = 0; i < this.accounts.length; i++) {
                    if (this.account.code === this.accounts[i].code) {
                        check_exist_code = false;
                    }
                }
                if (check_exist_code == false) {
                    this.codeError = {
                        text: "Mã khách hàng đã tồn tại! hãy thay đổi",
                        status: true
                    }
                    this.codeSuccess = {
                        text: "",
                        status: false
                    }
                } else if (this.account.code.length >= 5) {
                    this.codeSuccess = {
                        text: "Thành công!",
                        status: true
                    }
                    this.codeError = {
                        text: "",
                        status: false
                    }

                }
            }
            if (this.account.name.length == 0) {
                this.nameError = {
                    text: "Tên không được để trống!",
                    status: true
                }
                this.nameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.account.name.length < 6 || this.account.name.length > 50) {
                this.nameError = {
                    text: "Tên phải từ 6 đến 50 kí tự",
                    status: true
                }
                this.nameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.account.name.length > 6 || this.account.name.length < 50) {
                this.nameSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.nameError = {
                    text: "",
                    status: false
                }
            } else {
                this.nameError = {
                    text: "",
                    status: false
                }
            }
            const regex = /^\w+([.-]?\w+)*@[a-z]+([.-]?\w+)*(.\w{2,3})+$/;
            if (this.account.email.length == 0 || this.account.email == '') {
                this.emailError.text = "Email không được để trống!",
                    this.emailError.status = true;
                this.emailSuccess.text = "";
                this.emailSuccess.status = false;

            } else if (!regex.test(this.account.email)) {
                this.emailError.text = "Email sai định dạng !",
                    this.emailError.status = true;
                this.emailSuccess.text = "";
                this.emailSuccess.status = false;
            } else if (regex.test(this.account.email)) {
                this.emailSuccess.text = "Success!!";
                this.emailSuccess.status = true;
                this.emailError.status = false
            } else {
                this.emailError.text = "",
                    this.emailError.status = false
            }
            const regex_phone = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
            if (this.account.phone.length == 0) {
                this.phoneError = {
                    text: "Số điện thoại không được để trống",
                    status: true
                }
                this.phoneSuccess = {
                    text: "",
                    status: false
                }
            } else if (!regex_phone.test(this.account.phone)) {
                this.phoneError.text = "Số điện thoại sai định dạng!",
                    this.phoneError.status = true;
                this.phoneSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.account.phone.length != 10) {
                this.phoneError = {
                    text: "Số điện thoại phải có 10 kí tự ",
                    status: true
                }
                this.phoneSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.account.phone.length < 11 && this.account.phone.length > 9) {
                this.phoneSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.phoneError = {
                    text: "",
                    status: false
                }
            } else {
                this.phoneError = {
                    text: "",
                    status: false
                }
            }
            if (this.account.password.length == 0) {
                this.passwordError = {
                    text: "Mật khẩu không được để trống",
                    status: true
                }
                this.passwordSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.account.password.length < 6) {
                this.passwordError = {
                    text: "Mật khẩu phải lớn hơn 6 kí tự ",
                    status: true
                }
                this.passwordSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.account.password.length >= 6) {
                this.passwordSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.passwordError = {
                    text: "",
                    status: false
                }
            } else {
                this.passwordError = {
                    text: "",
                    status: false
                }
            }
            
            if (this.codeSuccess.status == true && this.nameSuccess.status == true && this.emailSuccess.status == true && this.phoneSuccess.status == true && this.passwordSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    AccountService.create(this.account)
                        .then((res) => {
                            //Perform Success Action
                            this.ID = res.data.id;
                            this.account.id = this.ID;
                            this.account.status = true;
                            console.log(this.account);
                            this.$emit("ShowData", this.account);
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                        })
                        .finally(() => {
                            //Perform action in always
                        });
                    createToast({
                        title: 'Thêm mới thành công',
                    }, {
                        position: 'top-right',
                        type: 'success',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Thêm mới thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },
    }

}
</script>
