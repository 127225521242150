<template>
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
        <!-- Brand Logo -->
        <a href="index3.html" class="brand-link">
            <img src="../assets/logo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3"
                style="opacity: .8">
            <span class="brand-text font-weight-light">Bảo hiểm Vietlife</span>
        </a>

        <!-- Sidebar -->
        <div class="sidebar">
            <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                <div class="image">
                    <img src="assets/dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image">
                </div>
                <div class="info">
                    <a href="#" class="d-block">{{ user_name }}</a>

                </div>
                <div class="form-groud" v-if="login">
                    <button style="font-size: 14px;" @click.prevent="logOut" type="button"
                        class="btn btn-block btn-outline-warning">Đăng Xuất</button>
                </div>

            </div>
            <!-- Sidebar user panel (optional) -->

            <!-- SidebarSearch Form -->
            <div class="form-inline">
                <div class="input-group" data-widget="sidebar-search">
                    <input class="form-control form-control-sidebar" type="search" placeholder="Search"
                        aria-label="Search">
                    <div class="input-group-append">
                        <button class="btn btn-sidebar">
                            <i class="fas fa-search fa-fw"></i>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Sidebar Menu -->
            <nav class="mt-2">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                    data-accordion="false">
                    <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->

                    <li class="nav-item menu-open">
                        <a href="#" class="nav-link active">
                            <router-link :to="{ name: 'Home' }">
                                <i class="fa-solid fa-house" style="padding: 0px 3px;"></i>
                                <p>
                                    Trang chủ
                                </p>
                            </router-link>
                        </a>
                        <ul class="nav nav-treeview">

                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Các trang Seo
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                            <li class="nav-item">
                                <router-link :to="{ name: 'homeseo' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Home User Seo</p>
                                    </a>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'contactseo' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Contact User Seo</p>
                                    </a>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Tài khoản
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                            <li class="nav-item">
                                <router-link :to="{ name: 'account' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-user pr-2 pl-1"></i>
                                        <p>Tài khoản Admin</p>
                                    </a>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'account_user' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-user pr-2 pl-1"></i>
                                        <p>Tài khoản khách hàng</p>
                                    </a>
                                </router-link>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <router-link :to="{ name: 'banner' }">
                            <a href="" class="nav-link">
                                <i class="fa-solid fa-desktop pr-2 pl-1"></i>
                                <p>Banner</p>
                            </a>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'team' }">
                            <a href="" class="nav-link">
                                <i class="fa-solid fa-desktop pr-2 pl-1"></i>
                                <p>Đội ngũ nhân viên</p>
                            </a>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Dịch vụ
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                            <li class="nav-item">
                                <router-link :to="{ name: 'category' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Danh mục dịch vụ</p>
                                    </a>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'questionservice' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Câu hỏi danh mục dịch vụ</p>
                                    </a>
                                </router-link>
                            </li>
                            
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Bảo hiểm Sức khỏe
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                            <li class="nav-item">
                                <router-link :to="{ name: 'insuranceProduct' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Danh mục SPBH</p>
                                    </a>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'product' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Danh sách SPBH</p>
                                    </a>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'document' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Tài liệu</p>
                                    </a>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <a href="#" class="nav-link">
                                    <i class="nav-icon fas fa-tachometer-alt"></i>
                                    <p>
                                        Quyền lợi chính
                                        <i class="right fas fa-angle-left"></i>
                                    </p>
                                </a>
                                <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                    <li class="nav-item">
                                        <router-link :to="{ name: 'attribute' }">
                                            <a href="" class="nav-link">
                                                <i class="fa-solid fa-list pr-2 pl-1"></i>
                                                <p>Danh mục ĐTBH</p>
                                            </a>
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link :to="{ name: 'valueattribute' }">
                                            <a href="" class="nav-link">
                                                <i class="fa-solid fa-list pr-2 pl-1"></i>
                                                <p>Danh sách ĐTBH</p>
                                            </a>
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link :to="{ name: 'item' }">
                                            <a href="" class="nav-link">
                                                <i class="fa-solid fa-list pr-2 pl-1"></i>
                                                <p>Quyền lợi theo danh sách BH Sức khỏe</p>
                                            </a>
                                        </router-link>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <a href="#" class="nav-link">
                                    <i class="nav-icon fas fa-tachometer-alt"></i>
                                    <p>
                                        Quyền lợi mở rộng
                                        <i class="right fas fa-angle-left"></i>
                                    </p>
                                </a>
                                <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                    <li class="nav-item">
                                        <router-link :to="{ name: 'catecondition' }">
                                            <a href="" class="nav-link">
                                                <i class="fa-solid fa-list pr-2 pl-1"></i>
                                                <p>Danh mục QLMR</p>
                                            </a>
                                        </router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link :to="{ name: 'condition' }">
                                            <a href="" class="nav-link">
                                                <i class="fa-solid fa-list pr-2 pl-1"></i>
                                                <p>Danh sách QLMR Sức khỏe</p>
                                            </a>
                                        </router-link>
                                    </li>

                                </ul>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'gift' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Quà tặng bảo hiểm</p>
                                    </a>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'question' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Hỏi đáp SPBH</p>
                                    </a>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'insuranceSK' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Đơn báo giá bảo hiểm sức khỏe cá nhân</p>
                                    </a>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Bảo hiểm Du Lịch
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                            <li class="nav-item">
                                <router-link :to="{ name: 'insuranceDL' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Danh mục SPBH</p>
                                    </a>
                                </router-link>
                            </li>

                            <li class="nav-item">
                                <router-link :to="{ name: 'documentDL' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Tài liệu</p>
                                    </a>
                                </router-link>
                            </li>

                            <li class="nav-item">
                                <router-link :to="{ name: 'questionDL' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Hỏi đáp SPBH</p>
                                    </a>
                                </router-link>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Bảo hiểm Ô Tô
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                            <li class="nav-item">
                                <router-link :to="{ name: 'insuranceOTO' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Danh mục SPBH</p>
                                    </a>
                                </router-link>
                            </li>

                            <li class="nav-item">
                                <router-link :to="{ name: 'documentOTO' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Tài liệu</p>
                                    </a>
                                </router-link>
                            </li>

                            <li class="nav-item">
                                <router-link :to="{ name: 'questionOTO' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Hỏi đáp SPBH</p>
                                    </a>
                                </router-link>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Bảo hiểm Doanh nghiệp
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                            <li class="nav-item">
                                <router-link :to="{ name: 'insuranceProductDN' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Danh mục SPBH</p>
                                    </a>
                                </router-link>
                            </li>

                            <li class="nav-item">
                                <router-link :to="{ name: 'documentDN' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Tài liệu</p>
                                    </a>
                                </router-link>
                            </li>

                            <li class="nav-item">
                                <router-link :to="{ name: 'gift' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Quà tặng bảo hiểm</p>
                                    </a>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'questionDN' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Hỏi đáp SPBH</p>
                                    </a>
                                </router-link>
                            </li>

                            <li class="nav-item">
                                <router-link :to="{ name: 'insuranceBaoViet' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Đơn báo giá Bảo Việt doanh nghiệp</p>
                                    </a>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'insuranceAnPhat' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Đơn báo giá An Phát doanh nghiệp</p>
                                    </a>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Hỗ trợ
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                            <li class="nav-item">
                                <router-link :to="{ name: 'service' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Danh mục hỗ trợ</p>
                                    </a>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'servicedetail' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Danh sách hỗ trợ</p>
                                    </a>
                                </router-link>
                            </li>

                        </ul>
                    </li>
                    <!-- <li class="nav-item">
                    <router-link :to="{ name: 'application' }">
                        <a href="" class="nav-link">
                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                            <p>Danh sách lợi ích của danh mục sản phẩm bảo hiểm</p>
                        </a>
                    </router-link>
                </li>
                <li class="nav-item">
                    <a href="#" class="nav-link">
                        <i class="nav-icon fas fa-tachometer-alt"></i>
                        <p>
                            Đặc điểm nổi bật
                            <i class="right fas fa-angle-left"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                        <li class="nav-item">
                            <router-link :to="{ name: 'characteristic' }">
                                <a href="" class="nav-link">
                                    <i class="fa-solid fa-list pr-2 pl-1"></i>
                                    <p>Danh sách Đặc điểm nổi bật danh mục</p>
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'characterList' }">
                                <a href="" class="nav-link">
                                    <i class="fa-solid fa-list pr-2 pl-1"></i>
                                    <p>Danh sách Đặc điểm nổi bật</p>
                                </a>
                            </router-link>
                        </li>
                    </ul>
                </li> -->
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Tin tức
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                            <li class="nav-item">
                                <router-link :to="{ name: 'categoryNews' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Danh mục tin tức</p>
                                    </a>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'newslist' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Danh sách tin tức</p>
                                    </a>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Chính sách
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                            <li class="nav-item">
                                <router-link :to="{ name: 'policy' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Danh mục chính sách</p>
                                    </a>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'policylist' }">
                                    <a href="" class="nav-link">
                                        <i class="fa-solid fa-list pr-2 pl-1"></i>
                                        <p>Danh sách chính sách</p>
                                    </a>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'userLanding' }">
                            <a href="" class="nav-link">
                                <i class="fa-solid fa-address-card pr-2 pl-1"></i>
                                <p>TK khách hàng Landing</p>
                            </a>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'contact' }">
                            <a href="" class="nav-link">
                                <i class="fa-solid fa-address-card pr-2 pl-1"></i>
                                <p>Khách hàng Contact</p>
                            </a>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'feedback' }">
                            <a href="./index3.html" class="nav-link">
                                <i class="fa-solid fa-comments-dollar pr-2 pl-1"></i>
                                <p>Đánh giá của khách hàng</p>
                            </a>
                        </router-link>
                    </li>
                </ul>
            </nav>
            <!-- /.sidebar-menu -->
        </div>
        <!-- /.sidebar -->
    </aside>
</template>

<style></style>

<script>
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    setup() {
        let login = JSON.parse(sessionStorage.getItem("user"));
        let user_name = login.accountName;
        return {
            login,
            user_name
        }
    },
    methods: {
        logOut() {
            createToast({
                title: 'Đã đăng xuất thành công',
            }, {
                position: 'top-right',
                type: 'success',
                transition: 'zoom',
                showIcon: 'true',
                showCloseButton: 'false',
            })
            sessionStorage.removeItem('user');
            sessionStorage.removeItem('token');
            window.location.href = '/login';
        }
    }
};
</script>
