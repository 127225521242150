<template>
<div class="card card-success mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Thêm mới tài khoản Admin</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitForm">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row mb-4">
                            <label for="exampleFormControlInput1" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mã tài khoản</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="code" placeholder="" v-model="account.code" :class="{ error: codeError.status, success: codeSuccess.status }" />
                                <p class="text-error" v-if="codeError.status">{{ codeError.text }}</p>
                                <p class="success-text" v-if="codeSuccess.status">{{ codeSuccess.text }}
                                </p>
                            </div>

                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Họ tên</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="name" placeholder="" v-model="account.name" :class="{ error: nameError.status, success: nameSuccess.status }">
                                <p class="text-error" v-if="nameError.status">{{ nameError.text }}</p>
                                <p class="success-text" v-if="nameSuccess.status">{{ nameSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Email</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="email" placeholder="" v-model="account.email" :class="{ error: emailError.status, success: emailSuccess.status }">
                                <p class="text-error" v-if="emailError.status">{{ emailError.text }}</p>
                                <p class="success-text" v-if="emailSuccess.status">{{ emailSuccess.text }}
                                </p>
                            </div>
                        </div>

                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mật khẩu</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="password" class="form-control" id="password" placeholder="" v-model="account.password" :class="{ error: passwordError.status, success: passwordSuccess.status }">
                                <p class="text-error" v-if="passwordError.status">{{ passwordError.text }}</p>
                                <p class="success-text" v-if="passwordSuccess.status">{{ passwordSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row mb-4">
                            <label for="role" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Quyền hạn</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <select class="form-control  basic" v-model="account.role" id="role" :class="{ error: roleError.status, success: roleSuccess.status }">
                                    <option value=""> Chọn quyền:</option>
                                    <option v-bind:value="0">Admin Xem</option>
                                    <option v-bind:value="1">Admin Chỉnh sửa</option>
                                    <option v-bind:value="2">Admin Quản lý</option>
                                </select>
                                <p class="text-error" v-if="roleError.status">{{ roleError.text }}</p>
                                <p class="success-text" v-if="roleSuccess.status">{{ roleSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Thêm mới</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import AccountAdminService from '@/services/AccountAdminService';
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    data() {
        return {
            message: "",
            currentImage: undefined,
            url: null,
            ID: null,
            account: {
                id: null,
                code: "",
                name: "",
                email: "",
                password: "",
                role: "",
                status: true
            },
            codeError: {
                text: "",
                status: false,
            },
            codeSuccess: {
                text: "",
                status: false,
            },
            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            },
            emailError: {
                text: "",
                status: false,
            },
            emailSuccess: {
                text: "",
                status: false,
            },
            passwordError: {
                text: "",
                status: false,
            },
            passwordSuccess: {
                text: "",
                status: false,
            },
            roleError: {
                text: "",
                status: false,
            },
            roleSuccess: {
                text: "",
                status: false,
            }
        }

    },
    mounted() {

    },
    methods: {
        onSubmitForm() {

            if (this.account.code.length == 0) {
                this.codeError = {
                    text: "Mã tài khoản không được để trống",
                    status: true
                }
                this.codeSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.account.code.length < 4) {
                this.codeError = {
                    text: "Mã tài khoản phải trên 4 kí tự",
                    status: true
                }
                this.codeSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.account.code.length >= 4) {
                this.codeSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.codeError = {
                    text: "",
                    status: false
                }
            } else {
                this.codeError = {
                    text: "",
                    status: false
                }
            }

            if (this.account.name.length == 0) {
                this.nameError = {
                    text: "Tên không được để trống!",
                    status: true
                }
                this.nameSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.account.name.length < 6 || this.account.name.length > 50) {
                this.nameError = {
                    text: "Tên phải từ 6 đến 50 ký tự",
                    status: true
                }
                this.nameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.account.name.length > 6 || this.account.name.length < 50) {
                this.nameSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.nameError = {
                    text: "",
                    status: false
                }
            } else {
                this.nameError = {
                    text: "",
                    status: false
                }
            }
            const regex = /^\w+([.-]?\w+)*@[a-z]+([.-]?\w+)*(.\w{2,3})+$/;
            if (this.account.email.length == 0) {
                this.emailError.text = "Email không được để trống!",
                    this.emailError.status = true
                this.emailSuccess = {
                    text: "",
                    status: false
                }
            } else if (!regex.test(this.account.email)) {
                this.emailError.text = "Email không đúng định dạng !",
                    this.emailError.status = true
                this.emailSuccess = {
                    text: "",
                    status: false
                }
            } else if (regex.test(this.account.email)) {
                this.emailSuccess.text = "Thành công!!";
                this.emailSuccess.status = true;
                this.emailError.status = false
            } else {
                this.emailError.text = "",
                    this.emailError.status = false
            }

            if (this.account.password.length == 0) {
                this.passwordError = {
                    text: "Mật khẩu không được để trống",
                    status: true
                }
                this.passwordSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.account.password.length < 6) {
                this.passwordError = {
                    text: "Mật khẩu phải lớn hơn 6 kí tự ",
                    status: true
                }
                this.passwordSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.account.password.length >= 6) {
                this.passwordSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.passwordError = {
                    text: "",
                    status: false
                }
            } else {
                this.passwordError = {
                    text: "",
                    status: false
                }
            }

            if (this.account.role.length == '') {
                this.roleError = {
                    text: "Hãy chọn phân quyền!",
                    status: true
                }
                this.roleSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.account.role.length != '') {
                this.roleSuccess = {
                    text: "Thành công!",
                    status: true,
                };
                this.roleError = {
                    text: "",
                    status: false,
                };
            } else {
                this.roleError = {
                    text: "",
                    status: false,
                };
            }

            if (this.codeSuccess.status == true && this.nameSuccess.status == true && this.emailSuccess.status == true && this.passwordSuccess.status == true && this.roleSuccess.status == true) {
                if (this.account.gender == "") {
                    this.account.gender = false;
                }
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 2) {
                    AccountAdminService.create(this.account)
                        .then((res) => {
                            //Perform Success Action
                            this.ID = res.data.id;
                            this.account.id = this.ID;
                            this.account.status = true;
                            this.$emit("ShowData", this.account);
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                        })
                        .finally(() => {
                            //Perform action in always
                        });
                    createToast({
                        title: 'Thêm mới thành công',
                    }, {
                        position: 'top-right',
                        type: 'success',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                } else {
                    createToast({
                        title: 'Bạn không có quyền sử dụng chức năng này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Thêm mới thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }

        },
    }

}
</script>
