import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class InsurancequoteformBaoVietService {
    
    getAll() {
        return http.get("/QuoteInformationBaoViets",httpOptions);
    }
    get(id) {
        return http.get(`/QuoteInformationBaoViets/${id}`,httpOptions);
    }
    create(data) {
        return http.post("/QuoteInformationBaoViets", data,httpOptions);
    }
    update(data) {
        return http.post(`/QuoteInformationBaoViets/PutQuoteInformationBaoViets`, data,httpOptions);
    }
    // updatedate(data) {
    //     return http.post(`/QuoteInformationBaoViets/QuoteInformationBaoVietsHangfire`, data,httpOptions);
    // }
    delete(id) {
            return http.delete(`/QuoteInformationBaoViets/${id}`,httpOptions);
        }
        //   deleteAll() {
        //     return http.delete(`/tutorials`);
        //   }
        //   findByTitle(title) {
        //     return http.get(`/tutorials?title=${title}`);
        //   }
}
export default new InsurancequoteformBaoVietService();