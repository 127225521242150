<template>
<div class="card card-success mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Thêm mới Đánh giá khách hàng</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" enctype="multipart/form-data" v-on:submit.prevent="onSubmitForm">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row mb-4">
                            <label for="exampleFormControlInput1" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mã :</label>
                            <div class="col-xl-10 col-lg-10 col-sm-10">
                                <input type="text" class="form-control" id="code" placeholder="" v-model="feedback.code" :class="{ error: codeError.status, success: codeSuccess.status }" />
                                <p class="text-error" v-if="codeError.status">
                                    {{ codeError.text }}
                                </p>
                                <p class="success-text" v-if="codeSuccess.status">
                                    {{ codeSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Tiêu đề :</label>
                            <div class="col-xl-10 col-lg-10 col-sm-10">
                                <input type="text" class="form-control" id="name" placeholder="" v-model="feedback.title" :class="{ error: nameError.status, success: nameSuccess.status }" />
                                <p class="text-error" v-if="nameError.status">
                                    {{ nameError.text }}
                                </p>
                                <p class="success-text" v-if="nameSuccess.status">
                                    {{ nameSuccess.text }}
                                </p>
                            </div>
                        </div>
                        
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row mb-4">
                            <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">Ảnh</label>
                            <div class="col-4">
                                <p class="btn btn-success btn-sm" @click="$refs.file.click()">
                                    Choose file
                                </p>
                            </div>
                            <div class="col-8">
                                <label class="btn btn-default p-0">
                                    <input type="file" accept="image/*" ref="file" name="file" @change="selectImage" :hidden="true" />
                                </label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-sm-10">
                                <img v-if="url" :src="url" style="width: 400px; height: 300px; margin-left: 20%" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group mb-4">
                    <label for="exampleFormControlInput1" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mô tả :</label>
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <textarea style="height: 206px;" name="" class="form-control" id="description" cols="30" rows="10" v-model="feedback.description" :class="{ error: descriptionError.status, success: descriptionSuccess.status }"></textarea>
                        <p class="text-error" v-if="descriptionError.status">
                            {{ descriptionError.text }}
                        </p>
                        <p class="success-text" v-if="descriptionSuccess.status">
                            {{ descriptionSuccess.text }}
                        </p>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Thêm mới</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import FeedbackService from "../../services/FeedbackService";
import UploadService from "../../services/UploadService";
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    props: ["feedbacks"],
    name: "add-feedback",
    data() {
        return {
            getfeedback: this.feedback,
            message: "",
            accountuser: null,
            url: null,
            ID: null,
            a: null,
            feedback: {
                id: null,
                code: "",
                title: "",
                description: "",
                status: true,
            },
            codeError: {
                text: "",
                status: false,
            },
            codeSuccess: {
                text: "",
                status: false,
            },
            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            },
            descriptionError: {
                text: "",
                status: false,
            },
            descriptionSuccess: {
                text: "",
                status: false,
            },
        };
    },
    mounted() {
       
    },
    methods: {
        selectImage() {
            this.currentImage = this.$refs.file.files.item(0);
            this.url = URL.createObjectURL(this.currentImage);
            this.feedback.image = this.$refs.file.files.item(0).name;
            this.ishowImage = true;
        },
        onSubmitForm() {
            if (this.feedback.code.length == 0) {
                this.codeError = {
                    text: "Mã không được để trống",
                    status: true,
                };
                this.codeSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.feedback.code.length > 0) {
                this.codeSuccess = {
                    text: "Thành công!",
                    status: true,
                };
                this.codeError = {
                    text: "",
                    status: false,
                };
            } else {
                this.codeError = {
                    text: "",
                    status: false,
                };
            }

            if (this.feedback.title.length == 0) {
                this.nameError = {
                    text: "Tên không được để trống",
                    status: true,
                };
                this.nameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.feedback.title.length > 0) {
                this.nameSuccess = {
                    text: "Thành công!",
                    status: true,
                };
                this.nameError = {
                    text: "",
                    status: false,
                };
            } else {
                this.nameError = {
                    text: "",
                    status: false,
                };
            }
            if (this.feedback.description.length == 0) {
                this.descriptionError = {
                    text: "Đánh giá khách hàng không được để trống",
                    status: true,
                };
                this.descriptionSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.feedback.description.length > 0) {
                this.descriptionSuccess = {
                    text: "Thành công!",
                    status: true,
                };
                this.descriptionError = {
                    text: "",
                    status: false,
                };
            } else {
                this.descriptionError = {
                    text: "",
                    status: false,
                };
            }
            if (this.codeSuccess.status == true && this.nameSuccess.status == true && this.descriptionSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    UploadService.upload(this.currentImage)
                        .then((response) => {
                            console.log(response);
                            this.message = response.data.message;
                        })
                        .catch((err) => {
                            this.message = "Unable to load image  ! " + err;
                            this.currentImage = undefined;
                        });
                    FeedbackService.create(this.feedback)
                        .then((res) => {
                            //Perform Success Action
                            this.ID = res.data.id;
                            this.feedback.id = this.ID;
                            this.feedback.status = true;

                            console.log(res.data);
                            this.$emit("ShowData", this.feedback);
                            createToast({
                                title: 'Thêm mới thành công',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                            createToast({
                                title: 'Đã có lỗi!',
                            }, {
                                position: 'top-right',
                                type: 'danger',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .finally(() => {
                            //Perform action in always
                        });
                    this.$emit("ShowEditData", this.feedback);

                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Thêm mới thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },

    },
};
</script>
