<template>
<div class="card card-success mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Thêm mới Đội ngũ</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" enctype="multipart/form-data" v-on:submit.prevent="onSubmitForm">
                <div class="row">
                    <div class="col-md-5">
                        <div class="form-group row mb-4">
                            <label for="exampleFormControlInput1" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Code :</label>
                            <div class="col-xl-10 col-lg-10 col-sm-10">
                                <input type="text" class="form-control" id="code" placeholder="" v-model="team.code" :class="{ error: codeError.status, success: codeSuccess.status }" />
                                <p class="text-error" v-if="codeError.status">
                                    {{ codeError.text }}
                                </p>
                                <p class="success-text" v-if="codeSuccess.status">
                                    {{ codeSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Tên :</label>
                            <div class="col-xl-10 col-lg-10 col-sm-10">
                                <input type="text" class="form-control" id="name" placeholder="" v-model="team.name" :class="{ error: nameError.status, success: nameSuccess.status }" />
                                <p class="text-error" v-if="nameError.status">
                                    {{ nameError.text }}
                                </p>
                                <p class="success-text" v-if="nameSuccess.status">
                                    {{ nameSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Chức vụ:</label>
                            <div class="col-xl-10 col-lg-10 col-sm-10">
                                <input type="text" class="form-control" id="position" placeholder="" v-model="team.position" :class="{ error: positionError.status, success: positionSuccess.status }" />
                                <p class="text-error" v-if="positionError.status">
                                    {{ positionError.text }}
                                </p>
                                <p class="success-text" v-if="positionSuccess.status">
                                    {{ positionSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group row mb-4">
                            <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">Ảnh</label>
                            <div class="col-4">
                                <p class="btn btn-success btn-sm" @click="$refs.file.click()">
                                    Choose file
                                </p>
                            </div>
                            <div class="col-8">
                                <label class="btn btn-default p-0">
                                    <input type="file" accept="image/*" ref="file" name="file" @change="selectImage" :hidden="true" />
                                </label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-sm-10">
                                <img v-if="url" :src="url" style="width: 400px; height: 300px; margin-left: 20%" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Thêm mới</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import TeamService from "../../services/TeamService";
import UploadService from "../../services/UploadService";
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    props: ["teams"],
    name: "add-team",
    data() {
        return {
            message: "",
            currentImage: undefined,
            url: null,
            ID: null,
            a: null,
            team: {
                id: null,
                code: "",
                name: "",
                title: "",
                position: "",
                image: "",
                status: true,
            },
            codeError: {
                text: "",
                status: false,
            },
            codeSuccess: {
                text: "",
                status: false,
            },
            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            },
            positionError: {
                text: "",
                status: false,
            },
            positionSuccess: {
                text: "",
                status: false,
            },
        };
    },
    mounted() {
        TeamService.getAll().then((res) => {
            this.a = res.data
        })
    },
    methods: {
        onSubmitForm() {

            var codeSame;
            for (let i = 0; i < this.a.length; i++) {
                const element = this.a[i];
                if (this.team.code == element.code) {
                    codeSame = element.code;
                    break;
                }
            }
            if (this.team.code.length == 0) {
                this.codeError = {
                    text: "Mã không được để trống",
                    status: true,
                };
                this.codeSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.team.code.length < 4) {
                this.codeError = {
                    text: "Mã phải lớn hơn 4 kí tự",
                    status: true,
                };
                this.codeSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.team.code == codeSame) {
                this.codeError = {
                    text: "Mã đã tồn tại !",
                    status: true
                }
                this.codeSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.team.code.length < 4 || this.team.code != codeSame) {
                this.codeSuccess = {
                    text: "Thành công!",
                    status: true,
                };
                this.codeError = {
                    text: "",
                    status: false,
                };
            } else {
                this.codeError = {
                    text: "",
                    status: false,
                };
            }

            if (this.team.name.length == 0) {
                this.nameError = {
                    text: "Tên không được để trống",
                    status: true,
                };
                this.nameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.team.name.length < 6 || this.team.name.length > 50) {
                this.nameError = {
                    text: "Tên phải từ 6 đến 50 kí tự",
                    status: true,
                };
                this.nameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.team.name.length > 6 || this.team.name.length < 50) {
                this.nameSuccess = {
                    text: "Thành công!",
                    status: true,
                };
                this.nameError = {
                    text: "",
                    status: false,
                };
            } else {
                this.nameError = {
                    text: "",
                    status: false,
                };
            }
            if (this.team.position.length == 0) {
                this.positionError = {
                    text: "Chức vụ không được để trống",
                    status: true,
                };
                this.positionSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.team.position.length > 0) {
                this.positionSuccess = {
                    text: "Thành công!",
                    status: true,
                };
                this.positionError = {
                    text: "",
                    status: false,
                };
            } else {
                this.positionError = {
                    text: "",
                    status: false,
                };
            }
            if (this.codeSuccess.status == true && this.nameSuccess.status == true && this.positionSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));

                if (login.role == 1 || login.role == 2) {
                    UploadService.upload(this.currentImage)
                        .then((response) => {
                            console.log(response);
                            this.message = response.data.message;
                        })
                        .catch((err) => {
                            this.message = "Unable to load image ! " + err;
                            this.currentImage = undefined;
                        });

                    TeamService.create(this.team)
                        .then((res) => {
                            //Perform Success Action
                            this.ID = res.data.id;
                            this.team.id = this.ID;
                            console.log(res.data);
                            this.$emit("ShowData", this.team);
                            createToast({
                                title: 'Thêm mới thành công',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                            createToast({
                                title: 'Đã có lỗi',
                            }, {
                                position: 'top-right',
                                type: 'danger',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .finally(() => {
                            //Perform action in always
                        });

                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Thêm mới thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },
        selectImage() {
            this.currentImage = this.$refs.file.files.item(0);
            this.url = URL.createObjectURL(this.currentImage);
            this.team.image = this.$refs.file.files.item(0).name;
        },
    },
};
</script>
