import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class QuestionDLService {
    getAll() {
        return http.get("/QuestionDLs",httpOptions);
    }
    get(id) {
        return http.get(`/QuestionDLs/${id}`,httpOptions);
    }
    create(data) {
        return http.post("/QuestionDLs", data,httpOptions);
    }
    update(data) {
        return http.post(`/QuestionDLs/PutQuestionDLs`, data,httpOptions);
    }
    delete(id) {
            return http.delete(`/QuestionDLs/${id}`,httpOptions);
        }
        //   deleteAll() {
        //     return http.delete(`/tutorials`);
        //   }
        //   findByTitle(title) {
        //     return http.get(`/tutorials?title=${title}`);
        //   }
}
export default new QuestionDLService();