<template>
<div class="card card-warning mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Chỉnh sửa Banner</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" enctype="multipart/form-data" v-on:submit.prevent="onSubmitEditForm" v-if="banners">
                <input type="hidden" v-model="banners.id" />
                <div class="row">
                    <div class="col-md-5">
                        <div class="form-group row mb-4">
                            <label for="exampleFormControlInput1" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Link URL
                                :</label>
                            <div class="col-xl-10 col-lg-10 col-sm-10">
                                <input type="text" class="form-control" id="code" placeholder="" v-model="banners.code" :class="{ error: codeError.status, success: codeSuccess.status }" />
                                <p class="text-danger mb-0 mt-2" v-if="codeError.status">
                                    <i>{{ codeError.text }}</i>
                                </p>
                                <p class="text-success mb-0 mt-2" v-if="codeSuccess.status">
                                    {{ codeSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Tên :</label>
                            <div class="col-xl-10 col-lg-10 col-sm-10">
                                <input type="text" class="form-control" id="name" placeholder="" v-model="banners.name" :class="{ error: nameError.status, success: nameSuccess.status }" />
                                <p class="text-danger mb-0 mt-2" v-if="nameError.status">
                                    {{ nameError.text }}
                                </p>
                                <p class="text-success mb-0 mt-2" v-if="nameSuccess.status">
                                    {{ nameSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <!-- <div class="form-group">
                            <label for="exampleFormControlInput1" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Ngày bắt đầu:</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <input type="datetime-local" class="form-control" id="start" placeholder="" v-model="banners.start" />
                            </div>

                        </div>
                        <div class="form-group">
                            <label for="exampleFormControlInput1" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Ngày kết thúc:</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <input type="datetime-local" class="form-control" id="ends" placeholder="" v-model="banners.ends" />
                            </div>

                        </div> -->
                        <!-- <div class="form-group row">
                            <div class="col-sm-10">
                                <button type="button" @click="onUpdateforTime()" class="btn btn-primary mt-3">Đặt lịch</button>
                            </div>
                        </div> -->
                        <fieldset class="form-group mb-4">
                            <div class="row">
                                <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">Trạng thái :</label>
                                <div class="col-xl-10 col-lg-9 col-sm-10">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="banners.status" :value="true" :checked="banners.status === true" id="status" style="width: 16px; height: 16px" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Hoạt động
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="banners.status" :value="false" :checked="banners.status === false || banners.status === ''" id="status" style="width: 16px; height: 16px" />
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Không hoạt động
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group row mb-4">
                            <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">Ảnh :</label>
                            <div class="col-4">
                                <p class="btn btn-success btn-sm" @click="$refs.file.click()">
                                    Chọn file
                                </p>
                            </div>
                            <div class="col-8">
                                <label class="btn btn-default p-0">
                                    <input type="file" accept="image/*" name="file" ref="file" @change="selectImage" multiple :hidden="true" />
                                </label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-sm-10">
                                <img :src="'https://vietlife.com.vn/InsuranceApi/images/' + banners.image" style="width: 400px; height: 300px; margin-left: 20%" v-if="ishowImage == false" />
                                <img v-bind:src="url" style="width: 300px; height: 250px; margin-left: 20%" v-if="ishowImage == true" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mô tả nhỏ:</label>
                            <div class="col-xl-10 col-lg-10 col-sm-10">
                                <ckeditor :editor="editor" @ready="onReady" :config="editorConfig" v-model="banners.title" :class="{ error: titleError.status, success: titleSuccess.status }"></ckeditor>
                                <p class="text-danger mb-0 mt-2" v-if="titleError.status">
                                    {{ titleError.text }}
                                </p>
                                <p class="text-success mb-0 mt-2" v-if="titleSuccess.status">
                                    {{ titleSuccess.text }}
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mô tả :</label>
                            <div class="col-xl-10 col-lg-10 col-sm-10">
                                <ckeditor :editor="editor" @ready="onReady" :config="editorConfig" v-model="banners.description" :class="{ error: descriptionError.status, success: descriptionSuccess.status }"></ckeditor>
                                <p class="text-danger mb-0 mt-2" v-if="descriptionError.status">
                                    {{ descriptionError.text }}
                                </p>
                                <p class="text-success mb-0 mt-2" v-if="descriptionSuccess.status">
                                    {{ descriptionSuccess.text }}
                                </p>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Xác nhận sửa</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import BannerService from "../../services/BannerService";
import UploadService from "../../services/UploadService";
import {
    ClassicEditor
} from '@ckeditor/ckeditor5-editor-classic';

import {
    Alignment
} from '@ckeditor/ckeditor5-alignment'; // Importing the package.
import {
    Autoformat
} from '@ckeditor/ckeditor5-autoformat';
import {
    BlockQuote
} from '@ckeditor/ckeditor5-block-quote';
import {
    CloudServices
} from '@ckeditor/ckeditor5-cloud-services';
import {
    Essentials
} from '@ckeditor/ckeditor5-essentials';
import {
    Heading
} from '@ckeditor/ckeditor5-heading';
import {
    Image,
    ImageCaption,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    ImageInsert,
    ImageResizeEditing,
    ImageResizeHandles,
} from '@ckeditor/ckeditor5-image';
import {
    Indent
} from '@ckeditor/ckeditor5-indent';
import {
    Link
} from '@ckeditor/ckeditor5-link';
import {
    List
} from '@ckeditor/ckeditor5-list';
import {
    MediaEmbed
} from '@ckeditor/ckeditor5-media-embed';
import {
    Paragraph
} from '@ckeditor/ckeditor5-paragraph';
import {
    PasteFromOffice
} from '@ckeditor/ckeditor5-paste-from-office';
import {
    Table,
    TableToolbar
} from '@ckeditor/ckeditor5-table';
import {
    TextTransformation
} from '@ckeditor/ckeditor5-typing';
import {
    SourceEditing
} from '@ckeditor/ckeditor5-source-editing';
import {
    GeneralHtmlSupport
} from '@ckeditor/ckeditor5-html-support';
import {
    CKFinder
} from '@ckeditor/ckeditor5-ckfinder';
import {
    UploadAdapter
} from '@ckeditor/ckeditor5-adapter-ckfinder';
import {
    Base64UploadAdapter,
    SimpleUploadAdapter
} from '@ckeditor/ckeditor5-upload';
import {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript
} from '@ckeditor/ckeditor5-basic-styles';
import {
    FontColor,
    FontBackgroundColor,
    FontFamily,
    FontSize
} from '@ckeditor/ckeditor5-font';
import {
    CodeBlock
} from '@ckeditor/ckeditor5-code-block';
import {
    Highlight
} from '@ckeditor/ckeditor5-highlight';
import {
    HorizontalLine
} from '@ckeditor/ckeditor5-horizontal-line';
import {
    RemoveFormat
} from '@ckeditor/ckeditor5-remove-format';
import {
    PageBreak
} from '@ckeditor/ckeditor5-page-break';
import {
    Style
} from '@ckeditor/ckeditor5-style';

import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    props: ["banner"],
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                plugins: [
                    Alignment,
                    Autoformat,
                    Heading,
                    Highlight,
                    CodeBlock,
                    BlockQuote,
                    CloudServices,
                    Essentials,
                    Bold,
                    Italic,
                    PageBreak,
                    Underline, Strikethrough, Subscript, Superscript,
                    FontColor, FontBackgroundColor, FontFamily, FontSize,
                    Link,
                    Style,
                    ImageInsert,
                    Paragraph,
                    Image,
                    ImageCaption,
                    ImageStyle,
                    ImageToolbar,
                    ImageUpload,
                    Table,
                    TableToolbar,
                    SourceEditing,
                    RemoveFormat,
                    GeneralHtmlSupport,
                    CKFinder,
                    HorizontalLine,
                    Base64UploadAdapter,
                    SimpleUploadAdapter,
                    ImageResizeEditing,
                    ImageResizeHandles,
                    UploadAdapter,
                    Indent,
                    List,
                    MediaEmbed,
                    PasteFromOffice,
                    TextTransformation
                ],
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'outdent',
                        'indent',
                        '|',
                        'ckfinder',
                        'insertImage',
                        'blockQuote',
                        'insertTable',
                        'mediaEmbed',
                        'undo',
                        'redo',
                        'alignment',
                        'fontBackgroundColor',
                        'fontColor',
                        'fontFamily',
                        'fontSize',
                        'highlight',
                        'horizontalLine',
                        'pageBreak',
                        'removeFormat',
                        'strikethrough',
                        'underline',
                        'style',
                        'sourceEditing'
                    ]
                },
                ckfinder: {
                    uploadUrl: '/ckfinder/connector?command=QuickUpload&type=Files&responseType=json',
                    filebrowserBrowseUrl: '/ckfinder/browser',
                    filebrowserImageBrowseUrl: '/ckfinder/browser?type=Images',
                    filebrowserUploadUrl: '/ckfinder/connector?command=QuickUpload&type=Files',
                    filebrowserImageUploadUrl: '/ckfinder/connector?command=QuickUpload&type=Images'
                },
                heading: {
                    options: [{
                            model: 'paragraph',
                            title: 'Paragraph',
                            class: 'ck-heading_paragraph'
                        },
                        {
                            model: 'heading1',
                            view: 'h1',
                            title: 'Heading 1',
                            class: 'ck-heading_heading1'
                        },
                        {
                            model: 'heading2',
                            view: 'h2',
                            title: 'Heading 2',
                            class: 'ck-heading_heading2'
                        },
                        {
                            model: 'heading3',
                            view: 'h3',
                            title: 'Heading 3',
                            class: 'ck-heading_heading3'
                        },
                        {
                            model: 'heading4',
                            view: 'h4',
                            title: 'Heading 4',
                            class: 'ck-heading_heading4'
                        },
                        {
                            model: 'heading5',
                            view: 'h5',
                            title: 'Heading 5',
                            class: 'ck-heading_heading5'
                        },
                        {
                            model: 'heading6',
                            view: 'h6',
                            title: 'Heading 6',
                            class: 'ck-heading_heading6'
                        }
                    ]
                },

                language: 'vi',
                highlight: {
                    options: [{
                            model: 'greenMarker',
                            class: 'marker-green',
                            title: 'Green marker',
                            color: 'rgb(25, 156, 25)',
                            type: 'marker'
                        },
                        {
                            model: 'yellowMarker',
                            class: 'marker-yellow',
                            title: 'Yellow marker',
                            color: '#cac407',
                            type: 'marker'
                        },
                        {
                            model: 'redPen',
                            class: 'pen-red',
                            title: 'Red pen',
                            color: 'hsl(343, 82%, 58%)',
                            type: 'pen'
                        }
                    ]
                },
                image: {
                    toolbar: [
                        'imageTextAlternative',
                        'imageStyle:inline',
                        'imageStyle:block',
                        'imageStyle:side',
                        'imageStyle:alignLeft',
                        'imageStyle:alignRight',
                        'imageStyle:alignCenter',
                        'imageStyle:alignBlockLeft',
                        'imageStyle:alignBlockRight',
                        'toggleImageCaption',
                        'link'
                    ]
                },
                style: {
                    definitions: [{
                            name: 'Article category',
                            element: 'h3',
                            classes: ['category']
                        },
                        {
                            name: 'Info box',
                            element: 'p',
                            classes: ['info-box']
                        },
                    ]
                },
                htmlSupport: {
                    allow: [{
                        name: /^.*$/,
                        styles: true,
                        attributes: true,
                        classes: true
                    }]
                },
                // Configuration of the FontSize plugin.
                fontSize: {
                    options: [
                        8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22
                    ],
                    supportAllValues: true
                },
                table: {
                    contentToolbar: [
                        'tableColumn',
                        'tableRow',
                        'mergeTableCells',
                        'tableCellProperties',
                        'tableProperties'
                    ]
                },
                fontFamily: {
                    options: [
                        'default',
                        'indieflowerregular',
                        'Arial, sans-serif',
                        'Verdana, sans-serif',
                        'Trebuchet MS',
                        'Apple Color Emoji',
                        'Segoe UI Emoji',
                        'Segoe UI Symbol',
                    ]
                },
                mediaEmbed: {
                    previewsInData: true,
                    extraProviders: [{
                        name: 'extraProvider',
                        url: /^example\.com\/media\/(\w+)/,

                    }, ]

                },
                licenseKey: ''
            },
            message: "",
            secondate: '',
            secondateremain: '',
            currentImage: undefined,
            url: null,
            banners: this.banner,
            ishowImage: false,
            codeError: {
                text: "",
                status: false,
            },
            codeSuccess: {
                text: "",
                status: false,
            },
            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            },
            descriptionError: {
                text: "",
                status: false,
            },
            descriptionSuccess: {
                text: "",
                status: false,
            },
            titleError: {
                text: "",
                status: false,
            },
            titleSuccess: {
                text: "",
                status: false,
            },
        };
    },

    methods: {
        onReady(editor) {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        selectImage() {
            this.currentImage = this.$refs.file.files.item(0);
            this.url = URL.createObjectURL(this.currentImage);
            this.banners.image = this.$refs.file.files.item(0).name;
            this.ishowImage = true;
        },

        onSubmitEditForm() {

            if (this.banners.code.length == 0) {
                this.codeError = {
                    text: "*URL không được để trống",
                    status: true,
                };
                this.codeSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.banners.code.length > 0) {
                this.codeSuccess = {
                    text: "Thành công!",
                    status: true,
                };
                this.codeError = {
                    text: "",
                    status: false,
                };
            } else {
                this.codeError = {
                    text: "",
                    status: false,
                };
            }

            if (this.banners.name.length == 0) {
                this.nameError = {
                    text: "*Tên không được để trống!",
                    status: true,
                };
                this.nameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.banners.name.length > 0) {
                this.nameSuccess = {
                    text: "Thành công!",
                    status: true,
                };
                this.nameError = {
                    text: "",
                    status: false,
                };
            } else {
                this.nameError = {
                    text: "",
                    status: false,
                };
            }
            if (this.banners.description.length == 0) {
                this.descriptionError = {
                    text: "Mô tả không được để trống",
                    status: true,
                };
                this.descriptionSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.banners.description.length > 0) {
                this.descriptionSuccess = {
                    text: "Thành công!",
                    status: true,
                };
                this.descriptionError = {
                    text: "",
                    status: false,
                };
            } else {
                this.descriptionError = {
                    text: "",
                    status: false,
                };
            }
            if (this.banners.title.length == 0) {
                this.titleError = {
                    text: "Mô tả không được để trống",
                    status: true,
                };
                this.titleSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.banners.title.length > 0) {
                this.titleSuccess = {
                    text: "Thành công!",
                    status: true,
                };
                this.titleError = {
                    text: "",
                    status: false,
                };
            } else {
                this.titleError = {
                    text: "",
                    status: false,
                };
            }

            if (this.codeSuccess.status == true && this.nameSuccess.status == true && this.descriptionSuccess.status == true && this.titleSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    UploadService.upload(this.currentImage)
                        .then((response) => {
                            console.log(response);
                            this.message = response.data.message;
                        })
                        .catch((err) => {
                            this.message = "Unable to load image  ! " + err;
                            this.currentImage = undefined;
                        });

                    BannerService.update(this.banners)
                        .then((res) => {
                            //Perform Success Action
                            this.banners = res.data;
                            this.$emit("ShowEditData", this.banners);
                            createToast({
                                title: 'Chỉnh sửa thành công',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                            createToast({
                                title: 'Chỉnh sửa thất bại',
                            }, {
                                position: 'top-right',
                                type: 'danger',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .finally(() => {
                            //Perform action in always
                        });
                    this.$emit("ShowEditData", this.banners);

                } else {
                    createToast({
                        title: 'Bạn không có quyền sử dụng chức năng này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Chỉnh sửa thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },
        // onUpdateforTime() {
        //     if (this.banners.start != null && this.banners.ends != null) {
        //         // var t1 = new Date(this.banners.ends);
        //         // var t2 = new Date(this.banners.start);
        //         // var t3 = new Date();

        //         // var dif = t1.getTime() - t2.getTime();
        //         // var Seconds_from_T1_to_T2 = (dif / 60000);
        //         // var Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);
        //         // this.secondate = Seconds_Between_Dates * 60;
        //         // console.log(this.secondate);

        //         // var dif1 = t2.getTime() - t3.getTime();
        //         // var Seconds_from_T3_to_T1 = (dif1 / 60000);
        //         // var secondremaining = Math.abs(Seconds_from_T3_to_T1);
        //         // this.secondateremain = secondremaining.toFixed(1) * 60;
        //         // console.log(this.secondateremain);

        //         // alert('Đã đặt lịch hẹn');
        //         BannerService.updatedate(this.banners)
        //             .then((res) => {
        //                 //Perform Success Action
        //                 this.banners = res.data;
        //                 this.$emit("ShowEditData", this.banners);
        //                 createToast({
        //                     title: 'Đã đặt lịch',
        //                 }, {
        //                     position: 'top-right',
        //                     type: 'success',
        //                     transition: 'zoom',
        //                     showIcon: 'true',
        //                     showCloseButton: 'false',
        //                 })

        //             })
        //             .catch((error) => {
        //                 // error.response.status Check status code
        //                 console.log(error);
        //                 createToast({
        //                     title: 'Thất bại',
        //                 }, {
        //                     position: 'top-right',
        //                     type: 'danger',
        //                     transition: 'zoom',
        //                     showIcon: 'true',
        //                     showCloseButton: 'false',
        //                 })
        //             })
        //             .finally(() => {
        //                 //Perform action in always
        //             });
        //         // setTimeout(() => {
        //         //     BannerService.update(this.banners)
        //         //         .then((res) => {
        //         //             //Perform Success Action
        //         //             this.banners = res.data;
        //         //             this.$emit("ShowEditData", this.banners);
        //         //             createToast({
        //         //                 title: 'Lịch bắt đầu diễn ra',
        //         //             }, {
        //         //                 position: 'top-right',
        //         //                 type: 'success',
        //         //                 transition: 'zoom',
        //         //                 showIcon: 'true',
        //         //                 showCloseButton: 'false',
        //         //             })
        //         //         })
        //         //         .catch((error) => {
        //         //             // error.response.status Check status code
        //         //             console.log(error);
        //         //             createToast({
        //         //                 title: 'Chỉnh sửa thất bại',
        //         //             }, {
        //         //                 position: 'top-right',
        //         //                 type: 'danger',
        //         //                 transition: 'zoom',
        //         //                 showIcon: 'true',
        //         //                 showCloseButton: 'false',
        //         //             })
        //         //         })
        //         //         .finally(() => {
        //         //             //Perform action in always
        //         //         });
        //         //     setTimeout(() => {
        //         //         alert('Đã cập nhật');
        //         //         this.banners.status = true;
        //         //         BannerService.update(this.banners)
        //         //             .then((res) => {
        //         //                 //Perform Success Action
        //         //                 this.banners = res.data;
        //         //                 this.$emit("ShowEditData", this.banners);
        //         //                 createToast({
        //         //                     title: 'Kết thúc',
        //         //                 }, {
        //         //                     position: 'top-right',
        //         //                     type: 'success',
        //         //                     transition: 'zoom',
        //         //                     showIcon: 'true',
        //         //                     showCloseButton: 'false',
        //         //                 })
        //         //             })
        //         //             .catch((error) => {
        //         //                 // error.response.status Check status code
        //         //                 console.log(error);
        //         //                 createToast({
        //         //                     title: 'Chỉnh sửa thất bại',
        //         //                 }, {
        //         //                     position: 'top-right',
        //         //                     type: 'danger',
        //         //                     transition: 'zoom',
        //         //                     showIcon: 'true',
        //         //                     showCloseButton: 'false',
        //         //                 })
        //         //             })
        //         //             .finally(() => {
        //         //                 //Perform action in always
        //         //             });
        //         //     }, (date1 * 1000))
        //         // }, (date2 * 1000))
        //         this.$emit("ShowEditData", this.banners);
        //     }
        // }
    },
};
</script>
