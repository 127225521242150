<template>
<div class="card card-success mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Thêm mới Khách hàng đăng ký</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitForm">
                <div class="form-group row mb-4">
                    <label for="exampleFormControlInput1" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mã khách hàng</label>
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <input type="text" class="form-control" id="code" placeholder="" v-model="userlanding.code" :class="{ error: codeError.status, success: codeSuccess.status }" />
                        <p class="text-error" v-if="codeError.status">{{ codeError.text }}</p>
                        <p class="success-text" v-if="codeSuccess.status">{{ codeSuccess.text }}
                        </p>
                    </div>

                </div>
                <div class="form-group row mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Họ tên</label>
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <input type="text" class="form-control" id="name" placeholder="" v-model="userlanding.name" :class="{ error: nameError.status, success: nameSuccess.status }">
                        <p class="text-error" v-if="nameError.status">{{ nameError.text }}</p>
                        <p class="success-text" v-if="nameSuccess.status">{{ nameSuccess.text }}
                        </p>
                    </div>
                </div>
                <div class="form-group row mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Email</label>
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <input type="text" class="form-control" id="email" placeholder="" v-model="userlanding.email" :class="{ error: emailError.status, success: emailSuccess.status }">
                        <p class="text-error" v-if="emailError.status">{{ emailError.text }}</p>
                        <p class="success-text" v-if="emailSuccess.status">{{ emailSuccess.text }}
                        </p>
                    </div>
                </div>
                <div class="form-group row mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Số ĐT</label>
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <input type="text" class="form-control" id="phone" placeholder="" v-model="userlanding.phone" :class="{ error: phoneError.status, success: phoneSuccess.status }">
                        <p class="text-error" v-if="phoneError.status">{{ phoneError.text }}</p>
                        <p class="success-text" v-if="phoneSuccess.status">{{ phoneSuccess.text }}
                        </p>
                    </div>
                </div>
                <div class="form-group row mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Loại bảo hiểm</label>
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <input type="text" class="form-control" id="categoryInsurance" placeholder="" v-model="userlanding.categoryInsurance" :class="{ error: categoryInsuranceError.status, success: categoryInsuranceSuccess.status }">
                        <p class="text-error" v-if="categoryInsuranceError.status">{{ categoryInsuranceError.text }}</p>
                        <p class="success-text" v-if="categoryInsuranceSuccess.status">{{ categoryInsuranceSuccess.text }}
                        </p>
                    </div>
                </div>
                <div class="form-group row mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Sản phẩm bảo hiểm</label>
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <input type="text" class="form-control" id="productInsurance" placeholder="" v-model="userlanding.productInsurance" :class="{ error: productInsuranceError.status, success: productInsuranceSuccess.status }">
                        <p class="text-error" v-if="productInsuranceError.status">{{ productInsuranceError.text }}</p>
                        <p class="success-text" v-if="productInsuranceSuccess.status">{{ productInsuranceSuccess.text }}
                        </p>
                    </div>
                </div>
                <div class="form-group row mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Loại hình tư vấn</label>
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <input type="text" class="form-control" id="consulting" placeholder="" v-model="userlanding.consulting" :class="{ error: consultingError.status, success: consultingSuccess.status }">
                        <p class="text-error" v-if="consultingError.status">{{ consultingError.text }}</p>
                        <p class="success-text" v-if="consultingSuccess.status">{{ consultingSuccess.text }}
                        </p>
                    </div>
                </div>
                <div class="form-group row mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Hình thức tư vấn</label>
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <input type="text" class="form-control" id="typeContact" placeholder="" v-model="userlanding.typeContact" :class="{ error: typeContactError.status, success: typeContactSuccess.status }">
                        <p class="text-error" v-if="typeContactError.status">{{ typeContactError.text }}</p>
                        <p class="success-text" v-if="typeContactSuccess.status">{{ typeContactSuccess.text }}
                        </p>
                    </div>
                </div>
                <div class="form-group row mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Ngày đặt lịch tư vấn</label>
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <input type="datetime-local" class="form-control" id="createdDate" placeholder="" v-model="userlanding.createdDate" :class="{ error: createdDateError.status, success: createdDateSuccess.status }">
                        <p class="text-error" v-if="createdDateError.status">{{ createdDateError.text }}</p>
                        <p class="success-text" v-if="createdDateSuccess.status">{{ createdDateSuccess.text }}
                        </p>
                    </div>
                </div>
                <div class="form-group row mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Ghi chú</label>
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <textarea rows="3" class="form-control" id="note" placeholder="" v-model="userlanding.note" :class="{ error: noteError.status, success: noteSuccess.status }"></textarea>
                        <p class="text-error" v-if="noteError.status">{{ noteError.text }}</p>
                        <p class="success-text" v-if="noteSuccess.status">{{ noteSuccess.text }}
                        </p>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Thêm mới</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import UserLandingService from '@/services/UserLandingService.js';
import AccountService from '@/services/UserLandingService.js';
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    data() {
        return {
            message: "",
            currentImage: undefined,
            accounts: null,
            url: null,
            ID: null,
            userlanding: {
                id: null,
                code: "",
                name: "",
                email: "",
                phone: "",
                note: "",
                categoryInsurance: "",
                productInsurance: "",
                consulting: "",
                typeContact: "",
                createdDate: "",
                status: true
            },
            codeError: {
                text: "",
                status: false,
            },
            codeSuccess: {
                text: "",
                status: false,
            },
            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            },
            emailError: {
                text: "",
                status: false,
            },
            emailSuccess: {
                text: "",
                status: false,
            },
            phoneError: {
                text: "",
                status: false,
            },
            phoneSuccess: {
                text: "",
                status: false,
            },
            categoryInsuranceError: {
                text: "",
                status: false,
            },
            categoryInsuranceSuccess: {
                text: "",
                status: false,
            },
            productInsuranceError: {
                text: "",
                status: false,
            },
            productInsuranceSuccess: {
                text: "",
                status: false,
            },
            consultingError: {
                text: "",
                status: false,
            },
            consultingSuccess: {
                text: "",
                status: false,
            },
            typeContactError: {
                text: "",
                status: false,
            },
            typeContactSuccess: {
                text: "",
                status: false,
            },
            createdDateError: {
                text: "",
                status: false,
            },
            createdDateSuccess: {
                text: "",
                status: false,
            },
            noteError: {
                text: "",
                status: false,
            },
            noteSuccess: {
                text: "",
                status: false,
            },

        }

    },
    mounted() {
        UserLandingService.getAll()
            .then((res) => {
                this.accounts = res.data;
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                //Perform action in always
            })

    },
    methods: {
        onSubmitForm() {
            if (this.userlanding.code.length == 0) {
                this.codeError = {
                    text: "Mã không được để trống",
                    status: true
                }
                this.codeSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.userlanding.code.length < 5) {
                this.codeError = {
                    text: "Mã phải lớn hơn 5 kí tự",
                    status: true
                }
                this.codeSuccess = {
                    text: "",
                    status: false
                }
            } else {
                var check_exist_code = true;
                for (var i = 0; i < this.accounts.length; i++) {
                    if (this.userlanding.code === this.accounts[i].code) {
                        check_exist_code = false;
                    }
                }
                if (check_exist_code == false) {
                    this.codeError = {
                        text: "Mã đã tồn tại !",
                        status: true
                    }
                    this.codeSuccess = {
                        text: "",
                        status: false
                    }
                } else if (this.userlanding.code.length >= 5) {
                    this.codeSuccess = {
                        text: "Thành công!",
                        status: true
                    }
                    this.codeError = {
                        text: "",
                        status: false
                    }

                }
            }
            if (this.userlanding.name.length == 0) {
                this.nameError = {
                    text: "Tên không được để trống!",
                    status: true
                }
                this.nameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.userlanding.name.length < 6 || this.userlanding.name.length > 50) {
                this.nameError = {
                    text: "Tên phải từ 6 đến 50 kí tự",
                    status: true
                }
                this.nameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.userlanding.name.length > 6 || this.userlanding.name.length < 50) {
                this.nameSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.nameError = {
                    text: "",
                    status: false
                }
            } else {
                this.nameError = {
                    text: "",
                    status: false
                }
            }
            const regex = /^\w+([.-]?\w+)*@[a-z]+([.-]?\w+)*(.\w{2,3})+$/;
            if (this.userlanding.email.length == 0 || this.userlanding.email == '') {
                this.emailError.text = "Email không được để trống!",
                    this.emailError.status = true;
                this.emailSuccess.text = "";
                this.emailSuccess.status = false;

            } else if (!regex.test(this.userlanding.email)) {
                this.emailError.text = "Email sai định dạng !",
                    this.emailError.status = true;
                this.emailSuccess.text = "";
                this.emailSuccess.status = false;
            } else if (regex.test(this.userlanding.email)) {
                this.emailSuccess.text = "Thành công!!";
                this.emailSuccess.status = true;
                this.emailError.status = false
            } else {
                this.emailError.text = "",
                    this.emailError.status = false
            }
            const regex_phone = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
            if (this.userlanding.phone.length == 0) {
                this.phoneError = {
                    text: "Số điện thoại không được để trống",
                    status: true
                }
                this.phoneSuccess = {
                    text: "",
                    status: false
                }
            } else if (!regex_phone.test(this.userlanding.phone)) {
                this.phoneError.text = "Số điện thoại sai định dạng !",
                    this.phoneError.status = true;
                this.phoneSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.userlanding.phone.length != 10) {
                this.phoneError = {
                    text: "Số điện thoại phải có 10 số",
                    status: true
                }
                this.phoneSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.userlanding.phone.length < 11 && this.userlanding.phone.length > 9) {
                this.phoneSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.phoneError = {
                    text: "",
                    status: false
                }
            } else {
                this.phoneError = {
                    text: "",
                    status: false
                }
            }

            if (this.codeSuccess.status == true && this.nameSuccess.status == true && this.emailSuccess.status == true && this.phoneSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    AccountService.create(this.userlanding)
                        .then((res) => {
                            //Perform Success Action
                            this.ID = res.data.id;
                            this.userlanding.id = this.ID;
                            this.userlanding.status = true;
                            console.log(this.userlanding);
                            this.$emit("ShowData", this.userlanding);
                            createToast({
                                title: 'Thêm mới thành công',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                            createToast({
                                title: 'Đã có lỗi',
                            }, {
                                position: 'top-right',
                                type: 'danger',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .finally(() => {
                            //Perform action in always
                        });

                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Thêm mới thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },
    }

}
</script>
