<template>
<div class="card card-success mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Thêm mới DM Chính sách</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitForm">
                <div class="form-group row mb-4">
                    <label for="exampleFormControlInput1" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mã DM chính sách</label>
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <input type="text" class="form-control" id="code" placeholder="" v-model="categorypolicy.code" :class="{ error: codeError.status, success: codeSuccess.status }" />
                        <p class="text-error" v-if="codeError.status">{{ codeError.text }}</p>
                        <p class="success-text" v-if="codeSuccess.status">{{ codeSuccess.text }}
                        </p>
                    </div>

                </div>
                <div class="form-group row mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Tên</label>
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <input type="text" class="form-control" id="name" placeholder="" v-model="categorypolicy.name" :class="{ error: nameError.status, success: nameSuccess.status }">
                        <p class="text-error" v-if="nameError.status">{{ nameError.text }}</p>
                        <p class="success-text" v-if="nameSuccess.status">{{ nameSuccess.text }}
                        </p>
                    </div>
                </div>
                <div class="form-group mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Tiêu đề Seo</label>
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <input type="text" class="form-control" id="titleSeo" placeholder="" v-model="categorypolicy.titleSeo" :class="{ error: titleSeoError.status, success: titleSeoSuccess.status }">
                        <p class="text-error" v-if="titleSeoError.status">{{ titleSeoError.text }}</p>
                        <p class="success-text" v-if="titleSeoSuccess.status">{{ titleSeoSuccess.text }}
                        </p>
                    </div>
                </div>
                <div class="form-group mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Key Words</label>
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <input type="text" class="form-control" id="metaKeyWords" placeholder="" v-model="categorypolicy.metaKeyWords" :class="{ error: metaKeyWordsError.status, success: metaKeyWordsSuccess.status }">
                        <p class="text-error" v-if="metaKeyWordsError.status">{{ metaKeyWordsError.text }}</p>
                        <p class="success-text" v-if="metaKeyWordsSuccess.status">{{ metaKeyWordsSuccess.text }}
                        </p>
                    </div>
                </div>
                <div class="form-group mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mô tả Seo</label>
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <textarea name="" id="metaDescription" style="width: 100%;" cols="30" rows="10" v-model="categorypolicy.metaDescription" :class="{ error: metaDescriptionError.status, success: metaDescriptionSuccess.status }"></textarea>
                        <p class="text-error" v-if="metaDescriptionError.status">{{ metaDescriptionError.text }}</p>
                        <p class="success-text" v-if="metaDescriptionSuccess.status">{{ metaDescriptionSuccess.text }}
                        </p>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Thêm mới</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import CategoryPolicyService from '@/services/CategoryPolicyService';
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    data() {
        return {
            message: "",
            currentImage: undefined,
            a: null,
            url: null,
            ID: null,
            categorypolicy: {
                id: null,
                code: "",
                name: "",
                titleSeo: "",
                metaKeyWords: "",
                metaDescription: "",
                status: true
            },
            codeError: {
                text: "",
                status: false,
            },
            codeSuccess: {
                text: "",
                status: false,
            },
            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            },

            titleSeoError: {
                text: "",
                status: false,
            },
            titleSeoSuccess: {
                text: "",
                status: false,
            },

            metaKeyWordsError: {
                text: "",
                status: false,
            },
            metaKeyWordsSuccess: {
                text: "",
                status: false,
            },

            metaDescriptionError: {
                text: "",
                status: false,
            },
            metaDescriptionSuccess: {
                text: "",
                status: false,
            },
        }

    },
    mounted() {
        CategoryPolicyService.getAll().then((res) => {
            this.a = res.data
        })
    },
    methods: {
        onSubmitForm() {
            var codeSame;
            for (let i = 0; i < this.a.length; i++) {
                const element = this.a[i];
                if (this.categorypolicy.code == element.code) {
                    codeSame = element.code;
                    break;
                }
            }
            if (this.categorypolicy.code.length == 0) {
                this.codeError = {
                    text: "Mã không được để trống",
                    status: true
                }
                this.codeSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.categorypolicy.code.length < 4) {
                this.codeError = {
                    text: "Mã phải lớn hơn 4 kí tự",
                    status: true
                }
                this.codeSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.categorypolicy.code == codeSame) {
                this.codeError = {
                    text: "Mã đã tồn tại !",
                    status: true
                }
                this.codeSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.categorypolicy.code.length >= 4 || this.categorypolicy.code == codeSame) {
                this.codeSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.codeError = {
                    text: "",
                    status: false
                }
            } else {
                this.codeError = {
                    text: "",
                    status: false
                }
            }

            if (this.categorypolicy.name.length == 0) {
                this.nameError = {
                    text: "Tên không được để trống!",
                    status: true
                }
                this.nameSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.categorypolicy.name.length < 6 || this.categorypolicy.name.length > 50) {
                this.nameError = {
                    text: "Tên phải từ 6 đến 50 kí tự",
                    status: true
                }
                this.nameSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.categorypolicy.name.length > 6 || this.categorypolicy.name.length < 50) {
                this.nameSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.nameError = {
                    text: "",
                    status: false
                }
            } else {
                this.nameError = {
                    text: "",
                    status: false
                }
            }

            if (this.codeSuccess.status == true && this.nameSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    CategoryPolicyService.create(this.categorypolicy)
                        .then((res) => {
                            //Perform Success Action
                            this.ID = res.data.id;
                            this.categorypolicy.id = this.ID;
                            this.categorypolicy.status = true;
                            this.$emit("ShowData", this.categorypolicy);
                            createToast({
                                title: 'Thêm mới thành công',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                            createToast({
                                title: 'Đã xảy ra lỗi!',
                            }, {
                                position: 'top-right',
                                type: 'danger',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .finally(() => {
                            //Perform action in always
                        });

                } else {
                    createToast({
                        title: 'Bạn không có quyền sử dụng chức năng này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Thêm mới thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }

        },
    }

}
</script>
