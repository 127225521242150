<template>
<div class="card card-warning mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Chỉnh sửa tài khoản khách hàng</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitEditForm" v-if="accounts">
                <input type="hidden" v-model="accounts.id">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row mb-4">
                            <label for="exampleFormControlInput1" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mã khách hàng</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="code" placeholder="" v-model="accounts.code" :class="{ error: codeError.status, success: codeSuccess.status }" />
                                <p class="text-error" v-if="codeError.status">{{ codeError.text }}</p>
                                <p class="success-text" v-if="codeSuccess.status">{{ codeSuccess.text }}
                                </p>
                            </div>

                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Họ tên</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input class="form-control" type="text" id="name" v-model="accounts.name" :class="{ error: nameError.status, success: nameSuccess.status }" />
                                <p class="text-error" v-if="nameError.status">{{ nameError.text }}</p>
                                <p class="success-text" v-if="nameSuccess.status">{{ nameSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="exampleFormControlInput1" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Email</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="email" placeholder="" v-model="accounts.email" :class="{ error: emailError.status, success: emailSuccess.status }" />
                                <p class="text-error" v-if="emailError.status">{{ emailError.text }}</p>
                                <p class="success-text" v-if="emailSuccess.status">{{ emailSuccess.text }}
                                </p>
                            </div>

                        </div>
                        <div class="form-group row mb-4">
                            <label for="exampleFormControlInput1" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Số ĐT</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="phone" placeholder="" v-model="accounts.phone" :class="{ error: phoneError.status, success: phoneSuccess.status }" />
                                <p class="text-error" v-if="phoneError.status">{{ phoneError.text }}</p>
                                <p class="success-text" v-if="phoneSuccess.status">{{ phoneSuccess.text }}
                                </p>
                            </div>

                        </div>
                        <div class="form-group row mb-4">
                            <label for="exampleFormControlInput1" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mật khẩu</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="password" class="form-control" id="password" placeholder="" v-model="accounts.password" :class="{ error: passwordError.status, success: passwordSuccess.status }" />
                                <p class="text-error" v-if="passwordError.status">{{ passwordError.text }}</p>
                                <p class="success-text" v-if="passwordSuccess.status">{{ passwordSuccess.text }}
                                </p>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-6">
                        <fieldset class="form-group mb-4">
                            <div class="row">
                                <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">Trạng thái</label>
                                <div class="col-xl-10 col-lg-9 col-sm-10">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="accounts.status" :value="true" :checked="accounts.status === true" id="status" style="width: 16px;height: 16px;" />
                                        <label class="form-check-label" for="flexCheckDefault"> Hoạt động
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="accounts.status" :value="false" :checked="accounts.status === false" id="status" style="width: 16px;height: 16px;" />
                                        <label class="form-check-label" for="flexCheckChecked"> Không hoạt động</label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Xác nhận sửa</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import AccountUserService from '@/services/AccountUserService';
import axios from 'axios'
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    props: ['account'],

    data() {

        return {
            accounts: this.account,
            url: null,
            ishowImage: false,
            message: "",
            accountss: null,

            codeError: {
                text: "",
                status: false,
            },
            codeSuccess: {
                text: "",
                status: false,
            },
            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            },
            emailError: {
                text: "",
                status: false,
            },
            emailSuccess: {
                text: "",
                status: false,
            },
            phoneError: {
                text: "",
                status: false,
            },
            phoneSuccess: {
                text: "",
                status: false,
            },
            passwordError: {
                text: "",
                status: false,
            },
            passwordSuccess: {
                text: "",
                status: false,
            }
        }

    },
    mounted() {
        AccountUserService.getAll()
            .then((res) => {
                this.accountss = res.data;
            });
    },
    methods: {
        onSubmitEditForm() {
            if (this.accounts.code.length == 0) {
                this.codeError = {
                    text: "Code cannot be empty",
                    status: true
                }
                this.codeSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.accounts.code.length < 5) {
                this.codeError = {
                    text: "Code must contain 5 characters",
                    status: true
                }
                this.codeSuccess = {
                    text: "",
                    status: false
                }
            } else{
                var check_exist_code = true;
                for (var i = 0; i < this.accountss.length; i++) {
                    if (this.accounts.code === this.accountss[i].code) {
                        check_exist_code = false;
                    }
                }
                if (check_exist_code == false) {
                    this.codeError = {
                        text: "Mã khách hàng đã tồn tại! hãy thay đổi",
                        status: true
                    }
                    this.codeSuccess = {
                        text: "",
                        status: false
                    }
                } else if (this.accounts.code.length >= 5) {
                    this.codeSuccess = {
                        text: "Thành công!",
                        status: true
                    }
                    this.codeError = {
                        text: "",
                        status: false
                    }

                }
            }
            if (this.accounts.name.length == 0) {
                this.nameError = {
                    text: "Tên không được để trống!",
                    status: true
                }
                this.nameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.accounts.name.length < 6 || this.accounts.name.length > 50) {
                this.nameError = {
                    text: "Tên phải từ 6 đến 50 kí tự",
                    status: true
                }
                this.nameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.accounts.name.length > 6 || this.accounts.name.length < 50) {
                this.nameSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.nameError = {
                    text: "",
                    status: false
                }
            } else {
                this.nameError = {
                    text: "",
                    status: false
                }
            }
            const regex = /^\w+([.-]?\w+)*@[a-z]+([.-]?\w+)*(.\w{2,3})+$/;
            if (this.accounts.email.length == 0 || this.accounts.email == '') {
                this.emailError.text = "Email không được để trống!",
                this.emailError.status = true;
                this.emailSuccess.text = "";
                this.emailSuccess.status = false;

            } else if (!regex.test(this.accounts.email)) {
                this.emailError.text = "Email sai định dạng !",
                this.emailError.status = true;
                this.emailSuccess.text = "";
                this.emailSuccess.status = false;
            } else if (regex.test(this.accounts.email)) {
                this.emailSuccess.text = "Thành công!!";
                this.emailSuccess.status = true;
                this.emailError.status = false
            } else {
                this.emailError.text = "",
                    this.emailError.status = false
            }
            const regex_phone = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
            if (this.accounts.phone.length == 0) {
                this.phoneError = {
                    text: "Số điện thoại không được để trống",
                    status: true
                }
                this.phoneSuccess = {
                    text: "",
                    status: false
                }
            } else if (!regex_phone.test(this.accounts.phone)) {
                this.emailError.text = "Số điện thoại sai định dạng !",
                    this.emailError.status = true;
                this.phoneSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.accounts.phone.length != 10) {
                this.phoneError = {
                    text: "Số điện thoại phải có 10 số ",
                    status: true
                }
                this.phoneSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.accounts.phone.length < 11 && this.accounts.phone.length > 9) {
                this.phoneSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.phoneError = {
                    text: "",
                    status: false
                }
            } else {
                this.phoneError = {
                    text: "",
                    status: false
                }
            }
            if (this.accounts.password.length == 0) {
                this.passwordError = {
                    text: "Mật khẩu không được để trống",
                    status: true
                }
                this.passwordSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.accounts.password.length < 6) {
                this.passwordError = {
                    text: "Mật khẩu phải lớn hơn 6 kí tự",
                    status: true
                }
                this.passwordSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.accounts.password.length >= 6) {
                this.passwordSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.passwordError = {
                    text: "",
                    status: false
                }
            } else {
                this.passwordError = {
                    text: "",
                    status: false
                }
            }

            if (this.codeSuccess.status == true && this.nameSuccess.status == true && this.emailSuccess.status == true && this.phoneSuccess.status == true && this.passwordSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                let token = sessionStorage.getItem("token");
                if (login.role == 1 || login.role == 2) {
                    AccountUserService.update(this.accounts)
                        .then((res) => {
                            console.log(res);
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                        })
                        .finally(() => {
                            //Perform action in always
                        });
                    axios.defaults.headers.common['Authorization'] = "Bearer" + token
                    this.$emit("ShowEditData", this.accounts);
                    createToast({
                        title: 'Chỉnh sửa thành công',
                    }, {
                        position: 'top-right',
                        type: 'success',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Chỉnh sửa thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },
    }

}
</script>
