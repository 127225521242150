<template>
<div class="card card-danger mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Danh sách tài khoản đã xóa</h5>
    </div>
    <div class="input-group mt-3 col-md-6">
        <div class="input-group-prepend">
            <span class="input-group-text">Tìm Kiếm</span>
        </div>
        <input type="text" v-model="query" class="form-control" placeholder="Tìm Kiếm">
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th class="text-center">Mã khách hàng</th>
                            <th>Họ tên</th>
                            <th>Email</th>
                            <th>Trạng thái</th>
                            <th class="text-center">Chức năng</th>
                        </tr>
                    </thead>
                    <tbody v-if="query">
                        <tr v-for="item in customerFilte" :key="item.id">
                            <td class="text-center">{{ item.code }}</td>
                            <td>{{ item.name }}</td>
                            <td>
                                {{ item.email }}
                            </td>
                            <td>
                                <p class="text-success">
                                    <span v-if="item.status">Hoạt động</span>
                                    <span v-if="!item.status">Không hoạt động</span>
                                </p>
                            </td>

                            <td class="text-center">
                                <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-warning mr-2" data-placement="top" title="Phục hồi" data-original-title="Edit" style="padding: 12px" v-on:click="onRepeat(item)"><i class="fa-solid fa-recycle"></i></a>
                                <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-danger" v-on:click.stop.prevent="onDelete(item)" data-placement="top" style="padding: 12px" title="Xóa" data-original-title="Delete"><i class="fa-solid fa-trash fa-lg"></i></a>

                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr v-for="item in paginated" :key="item.id">
                            <td class="text-center">{{ item.code }}</td>
                            <td>{{ item.name }}</td>
                            <td>
                                {{ item.email }}
                            </td>
                            <td>{{ item.address }}</td>
                            <td>
                                <p class="text-success">
                                    <span v-if="item.status">Hoạt động</span>
                                    <span v-if="!item.status">Không hoạt động</span>
                                </p>
                            </td>

                            <td class="text-center">
                                <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-warning mr-2" data-placement="top" title="Phục hồi" data-original-title="Edit" style="padding: 12px" v-on:click="onRepeat(item)"><i class="fa-solid fa-recycle"></i></a>
                                <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-danger" v-on:click.stop.prevent="onDelete(item)" data-placement="top" style="padding: 12px" title="Xóa" data-original-title="Delete"><i class="fa-solid fa-trash fa-lg"></i></a>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="column is-12">
                <div class="example-six">
                    <vue-awesome-paginate :total-items="resultCount" v-model="currentPage" :items-per-page="pageSize" :max-pages-shown="5" v-on:click="onClickHandler">
                        <template #prev-button>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="12" height="12" viewBox="0 0 24 24" style="vertical-align: inherit;">
                                    <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                                </svg>
                            </span>
                        </template>

                        <template #next-button>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="12" height="12" viewBox="0 0 24 24" style="vertical-align: inherit;">
                                    <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                                </svg>
                            </span>
                        </template>
                    </vue-awesome-paginate>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style>
.pagination-list {
    list-style: none;
    display: flex;
}

.btn-paginate {
    margin-inline: 5px;
    cursor: pointer;
    border-style: groove;
    border-radius: 100%;
    text-align: center;
    padding: 10%;
    color: #000;

}

.isActive {
    background-color: #80b500;
    color: #ffff;
}
</style>

<script>
import AccountUserService from "@/services/AccountUserService.js";
import "vue-awesome-paginate/dist/style.css";
import {
    createToast
} from 'mosha-vue-toastify';
import {
    ref
} from "vue";
import 'mosha-vue-toastify/dist/style.css';
export default {
    name: "Index",
    components: {},
    data() {
        return {
            customer: null,
            showEdit: null,
            isShowEdit: false,
            isShowAdd: false,
            isShowTrash: false,
            query: "",
            current: 1,
            pageSize: 5,
            isActive: false,
            trash: this.cate,
        }
    },
    created() {
        AccountUserService.getTrash()
            .then((res) => {
                this.customer = res.data;
                console.log(res);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                //Perform action in always
            })

    },
    setup() {
        const currentPage = ref(1);
        return {
            currentPage,
        };
    },
    computed: {
        resultCount() {
            return this.customer && this.customer.length
        },
        customerFilte() {
            if (this.query) {
                return this.customer.filter((customer) => {
                    return (
                        customer.name
                        .toLowerCase()
                        .indexOf(this.query.toLowerCase()) != -1
                    )
                })
            } else {
                return this.customer;
            }

        },
        indexStart() {
            return (this.currentPage * this.pageSize) - this.pageSize;
        },
        indexEnd() {
            return this.indexStart + this.pageSize;
        },
        paginated() {
            if (this.resultCount > 5) {
                return this.customer.slice(this.indexStart, this.indexEnd);
            } else {
                return this.customer;
            }
        }

    },
    methods: {
        onCurrent(item) {

            this.isActive = true
            return this.current = item;
        },

        prev() {
            this.current--;
            if (this.current == 0) {
                return this.current = 1;
            }
        },
        next() {
            this.current++;
            if (this.current > this.totalPaginate) {
                return this.current = this.totalPaginate;
            }
        },
        onRepeat(item) {
            AccountUserService.repeat(item)
                .then(response => {
                    console.log(response);
                    this.customer.splice(this.customer.findIndex(e => e.id == item.id), 1).push(response.data);
                    this.$emit("ShowDeleteData", item);
                })
                .catch(function (error) {
                    console.log(error)
                })
            createToast({
                title: 'Phục hồi thành công',
            }, {
                position: 'top-right',
                type: 'success',
                transition: 'zoom',
                showIcon: 'true',
                showCloseButton: 'false',
            })
        },
        onDelete(item) {
            let login = JSON.parse(sessionStorage.getItem("user"));
            if (login.role == 1 || login.role == 2) {
                if (confirm("Are you sure you want to delete " + item.code)) {
                    console.log(item.id);
                    AccountUserService.delete(item.id)
                        .then(response => {
                            console.log(response);
                            this.customer.splice(this.customer.findIndex(e => e.id == item.id), 1).push(response.data);
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                    createToast({
                        title: 'Xóa thành công',
                    }, {
                        position: 'top-right',
                        type: 'success',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                alert("Bạn không có quyền sử dụng chức năng này");
                createToast({
                    title: 'Bạn không có quyền này',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }

        }
    }

}
</script>
