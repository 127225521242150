<template>
<div class="card card-warning mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Chỉnh sửa Bảo hiểm</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitEditForm" v-if="insuranceProducts" enctype="multipart/form-data">
                <input type="hidden" v-model="insuranceProducts.id">
                <div class="row">
                    <!-- <div class="col-xl-12 col-lg-12 col-sm-12"> -->
                    <div class="col-xl-4 col-lg-4 col-sm-4">
                        <div class="form-group">
                            <label for="exampleFormControlInput1" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Mã Bảo hiểm (VD: DMSPBH01)</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <input type="text" class="form-control" id="code" placeholder="" v-model="insuranceProducts.code" :class="{ error: codeError.status, success: codeSuccess.status }" />
                                <p class="text-error" v-if="codeError.status">{{ codeError.text }}</p>
                                <p class="success-text" v-if="codeSuccess.status">{{ codeSuccess.text }}
                                </p>
                            </div>

                        </div>
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Tên Bảo hiểm</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <input type="text" class="form-control" id="name" placeholder="" v-model="insuranceProducts.name" :class="{ error: nameError.status, success: nameSuccess.status }">
                                <p class="text-error" v-if="nameError.status">{{ nameError.text }}</p>
                                <p class="success-text" v-if="nameSuccess.status">{{ nameSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <fieldset class="form-group mb-4">
                            <div class="row">
                                <label class="col-form-label col-xl-6 col-sm-6 col-sm-6 pt-0">Trạng thái</label>
                                <div class="col-xl-6 col-lg-6 col-sm-6">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="insuranceProducts.status" :value="true" :checked="insuranceProducts.status === true" id="status" style="width: 16px; height: 16px" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Hoạt động
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="insuranceProducts.status" :value="false" :checked="insuranceProducts.status === false || insuranceProducts.status === ''" id="status" style="width: 16px; height: 16px" />
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Không hoạt động
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <div class="col-xl-4 col-lg-4 col-sm-4">
                        <div class="form-group">
                            <label for="exampleFormControlInput1" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Phần trăm giảm giá</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <input type="text" class="form-control" id="salePrice" placeholder="" v-model="insuranceProducts.salePrice" :class="{ error: salePriceError.status, success: salePriceSuccess.status }" />
                                <p class="text-error" v-if="salePriceError.status">{{ salePriceError.text }}</p>
                                <p class="success-text" v-if="salePriceSuccess.status">{{ salePriceSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12" style="display: flex;">
                                <div class="col-lg-12" style="padding: 0;">
                                    <div class="form-group">
                                        <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Danh
                                            mục</label>
                                        <div class="col-xl-12 col-lg-12 col-sm-12">
                                            <select class="form-control basic" name="" id="category" v-model="insuranceProducts.category">
                                                <option value="">Choose</option>
                                                <!-- <option v-for="item in categorys" :key="item.id" :selected="insuranceProducts.categoryId === item.id" v-bind:value="item.id">
                                                    {{
                                                            item.name
                                                        }}</option> -->
                                                <option value="Bảo hiểm sức khỏe">Bảo hiểm sức khỏe</option>
                                                <option value="Bảo hiểm du lịch">Bảo hiểm du lịch</option>
                                                <option value="Bảo hiểm ô tô">Bảo hiểm ô tô</option>
                                                <option value="Bảo hiểm doanh nghiệp">Bảo hiểm doanh nghiệp</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-lg-6" style="display: flex;">
                                <div class="col-lg-12" style="padding: 0;">
                                    <div class="form-group">
                                        <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Tài liệu:</label>
                                        <div class="col-8">
                                            <label class="btn btn-default p-0">
                                                <input type="file" ref="file" name="file" multiple="multiple" @change="selectFile($event)"/>
                                            </label>
                                            <label for="">Tài liệu cũ:</label>
                                            <input type="text" name="text" disabled :value="insuranceProducts.fileDocument"/>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>

                        <div class="form-group">
                            <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">Ảnh</label>
                            <div class="col-4" style="margin-left: 20px;">
                                <p class="btn btn-success btn-sm" @click="$refs.file.click()">
                                    Choose file
                                </p>
                            </div>
                            <div class="col-8">
                                <label class="btn btn-default p-0">
                                    <input type="file" accept="image/*" name="file[]" multiple="multiple" ref="file" @change="selectImage" :hidden="true" />
                                </label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-sm-10">
                                <img :src="'https://vietlife.com.vn/InsuranceApi/images/' + insuranceProducts.image" style="width: 300px; height: 250px;" v-if="ishowImage == false" />
                                <img v-bind:src="url" style="width: 300px; height: 250px;" v-if="ishowImage == true" />
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-4 col-sm-4">
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Tài liệu đã có:</label>
                            <div class="document" style="padding: 5px 22px; box-shadow: 0 0 20px 0px rgba(38, 37, 37, 0.1);">
                                <p v-for="item in getdocument" :key="item.id"> {{ item.name }}</p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-4 col-sm-4 col-sm-4 col-form-label">Tên tài liệu :</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="name" placeholder="" v-model="document.name" :class="{ error: nameError.status, success: nameSuccess.status }" />
                                <p class="text-error" v-if="nameError.status">
                                    {{ nameError.text }}
                                </p>
                                <p class="success-text" v-if="nameSuccess.status">
                                    {{ nameSuccess.text }}
                                </p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Tài liệu:</label>
                            <div class="col-8">
                                <label class="btn btn-default p-0">
                                    <input type="file" accept=".pdf" @change="selectFile($event)" />
                                </label>
                                <!-- <label for="">Tài liệu cũ:</label>
                                            <input type="text" name="text" disabled :value="insuranceProducts.fileDocument"/> -->
                            </div>
                            <div class="col-md-12">
                                <div class="card-body" v-for="(items, i) in item" :key="i" style="box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);">
                                    <p>Tên tài liệu: {{ items.name }}</p>
                                    <p>File: {{ items.file }}</p>
                                    <button type="button" class="btn btn-danger" @click="removeItem(i)">Xóa</button>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-10">
                                    <button type="button" class="btn btn-info mt-3" @click="addItem">Thêm tạm thời</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Tiêu đề Seo</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <input type="text" class="form-control" id="titleSeo" placeholder="" v-model="insuranceProducts.titleSeo" :class="{ error: titleSeoError.status, success: titleSeoSuccess.status }">
                                <p class="text-error" v-if="titleSeoError.status">{{ titleSeoError.text }}</p>
                                <p class="success-text" v-if="titleSeoSuccess.status">{{ titleSeoSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Key Words</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <input type="text" class="form-control" id="metaKeyWords" placeholder="" v-model="insuranceProducts.metaKeyWords" :class="{ error: metaKeyWordsError.status, success: metaKeyWordsSuccess.status }">
                                <p class="text-error" v-if="metaKeyWordsError.status">{{ metaKeyWordsError.text }}</p>
                                <p class="success-text" v-if="metaKeyWordsSuccess.status">{{ metaKeyWordsSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mô tả Seo</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <textarea name="" id="metaDescription" style="width: 100%;" cols="30" rows="10" v-model="insuranceProducts.metaDescription" :class="{ error: metaDescriptionError.status, success: metaDescriptionSuccess.status }"></textarea>
                                <p class="text-error" v-if="metaDescriptionError.status">{{ metaDescriptionError.text }}
                                </p>
                                <p class="success-text" v-if="metaDescriptionSuccess.status">{{
                                        metaDescriptionSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-12">
                        <div class="form-group mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mô tả nhỏ</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <ckeditor :editor="editor" @ready="onReady" :config="editorConfig" v-model="insuranceProducts.title" :class="{ error: titleDescriptionError.status, success: titleDescriptionSuccess.status }"></ckeditor>

                                <p class="text-error" v-if="titleDescriptionError.status">{{ titleDescriptionError.text
                                    }}</p>
                                <p class="success-text" v-if="titleDescriptionSuccess.status">{{
                                        titleDescriptionSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Mô tả</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <ckeditor :editor="editor" @ready="onReady" :config="editorConfig" v-model="insuranceProducts.description" :class="{ error: descriptionError.status, success: descriptionSuccess.status }">
                                </ckeditor>

                                <p class="text-error" v-if="descriptionError.status">{{ descriptionError.text }}</p>
                                <p class="success-text" v-if="descriptionSuccess.status">{{ descriptionSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-10">
                            <button type="submit" class="btn btn-primary mt-3" style="width: 120px;">Xác nhận
                                sửa</button>
                        </div>
                    </div>
                    <!-- </div> -->

                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import DocumentDNService from "@/services/DocumentDNService";
import InsuranceProductDNService from '@/services/InsuranceProductDNService';
// import CategoryProductService from '@/services/CategoryProductService';
import UploadService from "@/services/UploadService";
import {
    ClassicEditor
} from '@ckeditor/ckeditor5-editor-classic';

import {
    Alignment
} from '@ckeditor/ckeditor5-alignment'; // Importing the package.
import {
    Autoformat
} from '@ckeditor/ckeditor5-autoformat';
import {
    BlockQuote
} from '@ckeditor/ckeditor5-block-quote';
import {
    CloudServices
} from '@ckeditor/ckeditor5-cloud-services';
import {
    Essentials
} from '@ckeditor/ckeditor5-essentials';
import {
    Heading
} from '@ckeditor/ckeditor5-heading';
import {
    Image,
    ImageCaption,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    ImageInsert,
    ImageResizeEditing,
    ImageResizeHandles,
} from '@ckeditor/ckeditor5-image';
import {
    Indent
} from '@ckeditor/ckeditor5-indent';
import {
    Link
} from '@ckeditor/ckeditor5-link';
import {
    List
} from '@ckeditor/ckeditor5-list';
import {
    MediaEmbed
} from '@ckeditor/ckeditor5-media-embed';
import {
    Paragraph
} from '@ckeditor/ckeditor5-paragraph';
import {
    PasteFromOffice
} from '@ckeditor/ckeditor5-paste-from-office';
import {
    Table,
    TableToolbar
} from '@ckeditor/ckeditor5-table';
import {
    TextTransformation
} from '@ckeditor/ckeditor5-typing';
import {
    SourceEditing
} from '@ckeditor/ckeditor5-source-editing';
import {
    GeneralHtmlSupport
} from '@ckeditor/ckeditor5-html-support';
import {
    CKFinder
} from '@ckeditor/ckeditor5-ckfinder';
import {
    UploadAdapter
} from '@ckeditor/ckeditor5-adapter-ckfinder';
import {
    Base64UploadAdapter,
    SimpleUploadAdapter
} from '@ckeditor/ckeditor5-upload';
import {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript
} from '@ckeditor/ckeditor5-basic-styles';
import {
    FontColor,
    FontBackgroundColor,
    FontFamily,
    FontSize
} from '@ckeditor/ckeditor5-font';
import {
    CodeBlock
} from '@ckeditor/ckeditor5-code-block';
import {
    Highlight
} from '@ckeditor/ckeditor5-highlight';
import {
    HorizontalLine
} from '@ckeditor/ckeditor5-horizontal-line';
import {
    RemoveFormat
} from '@ckeditor/ckeditor5-remove-format';
import {
    PageBreak
} from '@ckeditor/ckeditor5-page-break';
import {
    Style
} from '@ckeditor/ckeditor5-style';
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    props: ['insuranceProduct'],
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                plugins: [
                    Alignment,
                    Autoformat,
                    Heading,
                    Highlight,
                    CodeBlock,
                    BlockQuote,
                    CloudServices,
                    Essentials,
                    Bold,
                    Italic,
                    PageBreak,
                    Underline, Strikethrough, Subscript, Superscript,
                    FontColor, FontBackgroundColor, FontFamily, FontSize,
                    Link,
                    Style,
                    ImageInsert,
                    Paragraph,
                    Image,
                    ImageCaption,
                    ImageStyle,
                    ImageToolbar,
                    ImageUpload,
                    Table,
                    TableToolbar,
                    SourceEditing,
                    RemoveFormat,
                    GeneralHtmlSupport,
                    CKFinder,
                    HorizontalLine,
                    Base64UploadAdapter,
                    SimpleUploadAdapter,
                    ImageResizeEditing,
                    ImageResizeHandles,
                    UploadAdapter,
                    Indent,
                    List,
                    MediaEmbed,
                    PasteFromOffice,
                    TextTransformation
                ],
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'outdent',
                        'indent',
                        '|',
                        'ckfinder',
                        'insertImage',
                        'blockQuote',
                        'insertTable',
                        'mediaEmbed',
                        'undo',
                        'redo',
                        'alignment',
                        'fontBackgroundColor',
                        'fontColor',
                        'fontFamily',
                        'fontSize',
                        'highlight',
                        'horizontalLine',
                        'pageBreak',
                        'removeFormat',
                        'strikethrough',
                        'underline',
                        'style',
                        'sourceEditing'
                    ]
                },
                ckfinder: {
                    uploadUrl: '/ckfinder/connector?command=QuickUpload&type=Files&responseType=json',
                    filebrowserBrowseUrl: '/ckfinder/browser',
                    filebrowserImageBrowseUrl: '/ckfinder/browser?type=Images',
                    filebrowserUploadUrl: '/ckfinder/connector?command=QuickUpload&type=Files',
                    filebrowserImageUploadUrl: '/ckfinder/connector?command=QuickUpload&type=Images'
                },
                heading: {
                    options: [{
                            model: 'paragraph',
                            title: 'Paragraph',
                            class: 'ck-heading_paragraph'
                        },
                        {
                            model: 'heading1',
                            view: 'h1',
                            title: 'Heading 1',
                            class: 'ck-heading_heading1'
                        },
                        {
                            model: 'heading2',
                            view: 'h2',
                            title: 'Heading 2',
                            class: 'ck-heading_heading2'
                        },
                        {
                            model: 'heading3',
                            view: 'h3',
                            title: 'Heading 3',
                            class: 'ck-heading_heading3'
                        },
                        {
                            model: 'heading4',
                            view: 'h4',
                            title: 'Heading 4',
                            class: 'ck-heading_heading4'
                        },
                        {
                            model: 'heading5',
                            view: 'h5',
                            title: 'Heading 5',
                            class: 'ck-heading_heading5'
                        },
                        {
                            model: 'heading6',
                            view: 'h6',
                            title: 'Heading 6',
                            class: 'ck-heading_heading6'
                        }
                    ]
                },

                language: 'vi',
                highlight: {
                    options: [{
                            model: 'greenMarker',
                            class: 'marker-green',
                            title: 'Green marker',
                            color: 'rgb(25, 156, 25)',
                            type: 'marker'
                        },
                        {
                            model: 'yellowMarker',
                            class: 'marker-yellow',
                            title: 'Yellow marker',
                            color: '#cac407',
                            type: 'marker'
                        },
                        {
                            model: 'redPen',
                            class: 'pen-red',
                            title: 'Red pen',
                            color: 'hsl(343, 82%, 58%)',
                            type: 'pen'
                        }
                    ]
                },
                image: {
                    toolbar: [
                        'imageTextAlternative',
                        'imageStyle:inline',
                        'imageStyle:block',
                        'imageStyle:side',
                        'imageStyle:alignLeft',
                        'imageStyle:alignRight',
                        'imageStyle:alignCenter',
                        'imageStyle:alignBlockLeft',
                        'imageStyle:alignBlockRight',
                        'toggleImageCaption',
                        'link'
                    ]
                },
                style: {
                    definitions: [{
                            name: 'Article category',
                            element: 'h3',
                            classes: ['category']
                        },
                        {
                            name: 'Info box',
                            element: 'p',
                            classes: ['info-box']
                        },
                    ]
                },
                htmlSupport: {
                    allow: [{
                        name: /^.*$/,
                        styles: true,
                        attributes: true,
                        classes: true
                    }]
                },
                // Configuration of the FontSize plugin.
                fontSize: {
                    options: [
                        8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22
                    ],
                    supportAllValues: true
                },
                table: {
                    contentToolbar: [
                        'tableColumn',
                        'tableRow',
                        'mergeTableCells',
                        'tableCellProperties',
                        'tableProperties'
                    ]
                },
                fontFamily: {
                    options: [
                        'default',
                        'indieflowerregular',
                        'Arial, sans-serif',
                        'Verdana, sans-serif',
                        'Trebuchet MS',
                        'Apple Color Emoji',
                        'Segoe UI Emoji',
                        'Segoe UI Symbol',
                    ]
                },
                mediaEmbed: {
                    previewsInData: true,
                    extraProviders: [{
                        name: 'extraProvider',
                        url: /^example\.com\/media\/(\w+)/,

                    }, ]

                },
                licenseKey: ''
            },

            insuranceProducts: this.insuranceProduct,
            document: {
                id: null,
                name: "",
                insuranceProductId: this.insuranceProduct.id,
                file: "",
                files: null
            },
            getdocument: null,
            categoryName: null,
            giftName: null,
            item: [],
            url: null,
            ishowImage: false,
            // categorys: null,
            gifts: null,
            imagepro: null,
            message: "",
            old: "localhost:51248/images/" + this.insuranceProduct.image,
            currentImage: null,
            currentFile: null,
            codeError: {
                text: "",
                status: false,
            },
            codeSuccess: {
                text: "",
                status: false,
            },
            salePriceError: {
                text: "",
                status: false,
            },
            salePriceSuccess: {
                text: "",
                status: false,
            },
            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            },
            descriptionError: {
                text: "",
                status: false,
            },
            descriptionSuccess: {
                text: "",
                status: false,
            },
            categoryIdError: {
                text: "",
                status: false,
            },
            categoryIdSuccess: {
                text: "",
                status: false,
            },
            imagesError: {
                text: "",
                status: false,
            },
            imagesSuccess: {
                text: "",
                status: false,
            },

            titleSeoError: {
                text: "",
                status: false,
            },
            titleSeoSuccess: {
                text: "",
                status: false,
            },

            metaKeyWordsError: {
                text: "",
                status: false,
            },
            metaKeyWordsSuccess: {
                text: "",
                status: false,
            },

            metaDescriptionError: {
                text: "",
                status: false,
            },
            metaDescriptionSuccess: {
                text: "",
                status: false,
            },
            titleDescriptionError: {
                text: "",
                status: false,
            },
            titleDescriptionSuccess: {
                text: "",
                status: false,
            },
        }

    },
    mounted() {
        // CategoryProductService.getAll()
        //     .then((res) => {
        //         this.categorys = res.data;
        //     })
        //     .catch((error) => {
        //         console.log(error);

        //     })
        //     .finally(() => {

        //     })
        DocumentDNService.getInsurance(this.insuranceProducts.id)
            .then((res) => {
                this.getdocument = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
    },

    methods: {
        addItem() {
            if (this.document.file == '') {
                createToast({
                    title: 'Bạn không đc để trống',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            } else {
                this.item.push({
                    ...this.document
                });
            }
        },
        removeItem(i) {
            this.item.splice(i, 1);
        },
        selectFile(ev) {
            this.document.files = ev.target.files[0];
            this.document.file = ev.target.files[0].name;
        },
        onReady(editor) {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        editorInput(e) {
            // console.log(e)
            // this.$emit('getEditorData', e);
            this.editorData = e;
        },

        selectImage() {
            this.currentImage = this.$refs.file.files.item(0);
            this.url = URL.createObjectURL(this.currentImage);
            this.insuranceProducts.image = this.$refs.file.files.item(0).name;
            this.ishowImage = true;
        },
        onSubmitEditForm() {
            if (this.insuranceProducts.code.length == 0) {
                this.codeError = {
                    text: "Mã không được để trống",
                    status: true
                };
                this.codeSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.insuranceProducts.code.length < 4) {
                this.codeError = {
                    text: "Mã phải lớn hơn 4 kí tự ",
                    status: true
                }
                this.codeSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.insuranceProducts.code.length >= 4) {
                this.codeSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.codeError = {
                    text: "",
                    status: false
                }
            } else {
                this.codeSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.insuranceProducts.name.length == 0) {
                this.nameError = {
                    text: "Tên không được để!",
                    status: true
                };
                this.nameSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.insuranceProducts.name.length < 6 || this.insuranceProducts.name.length > 50) {
                this.nameError = {
                    text: "Tên phải từ 6 đến 50 kí tự",
                    status: true
                };
                this.nameSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.insuranceProducts.name.length > 6 || this.insuranceProducts.name.length < 50) {
                this.nameSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.nameError = {
                    text: "",
                    status: false
                }
            } else {
                this.nameSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.insuranceProducts.description.length == 0) {
                this.descriptionError = {
                    text: "Mô tả không được để trống",
                    status: true
                };
                this.descriptionSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.insuranceProducts.description.length > 0) {
                this.descriptionSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.descriptionError = {
                    text: "",
                    status: false
                }
            } else {
                this.descriptionSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.insuranceProducts.categoryId == '') {
                this.categoryIdError = {
                    text: "Hãy chọn danh mục !",
                    status: true
                };
                this.categoryIdSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.insuranceProducts.categoryId > 0) {
                this.categoryIdSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.categoryIdError = {
                    text: "",
                    status: false
                }
            } else {
                this.categoryIdSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.insuranceProducts.image.length == 0) {
                this.imagesError = {
                    text: "Ảnh không được để trống!",
                    status: true
                };
                this.imagesSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.insuranceProducts.image.length > 0) {
                this.imagesSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.imagesError = {
                    text: "",
                    status: false
                }
            } else {
                this.imagesSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.insuranceProducts.salePrice.length == 0) {
                this.salePriceError = {
                    text: "Hãy nhập phần trăm giảm giá!",
                    status: true
                };
                this.salePriceSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.insuranceProducts.salePrice.length > 0) {
                this.salePriceSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.salePriceError = {
                    text: "",
                    status: false
                }
            } else {
                this.salePriceSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.codeSuccess.status == true && this.nameSuccess.status == true && this.descriptionSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    UploadService.upload(this.currentImage)
                        .then((response) => {
                            console.log();
                            this.message = response.data.message;
                        })
                        .catch((err) => {
                            this.message = "Unable to load image  ! " + err;
                            this.currentImage = undefined;
                        });
                    InsuranceProductDNService.update(this.insuranceProducts)
                        .then((res) => {
                            console.log(res);
                            this.insuranceProducts.categoryName = res.data.categoryName;
                            this.$emit("ShowEditData", this.insuranceProducts);
                            createToast({
                                title: 'Chỉnh sửa thành công',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                            createToast({
                                title: 'Đã xảy ra lỗi',
                            }, {
                                position: 'top-right',
                                type: 'danger',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .finally(() => {
                            //Perform action in always
                        });
                    this.$emit("ShowEditData", this.insuranceProducts);
                    if (this.item.length >= 1) {
                        for (let i = 0; i < this.item.length; i++) {
                            const element = this.item[i];
                            UploadService.upload(element.files)
                                .then((response) => {
                                    console.log();
                                    this.message = response.data.message;
                                })
                                .catch((err) => {
                                    this.message = "Unable to load image  ! " + err;
                                    element.files = undefined;
                                });
                            DocumentDNService.create(element)
                                .then((res) => {
                                    this.ID = res.data.id;
                                    this.document.id = this.ID;
                                    this.document.status = true;
                                    this.document.productName = res.data.productName;

                                    this.$emit("ShowData", this.document);
                                })
                                .catch((error) => {
                                    // error.response.status Check status code
                                    console.log(error);
                                })
                                .finally(() => {
                                    //Perform action in always
                                });

                        }
                    }

                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Chỉnh sửa thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        }
    }

}
</script>
