import { createRouter, createWebHistory } from "vue-router";
import AccountAdmin from "./view/AccountAdmin/index";
import Category from "./view/Category/index";
import CategoryNews from "./view/CategoryNews/index"
import CategoryPolicy from "./view/CategoryPolicy/index"
import PolicyList from "./view/PolicyDetail/index"
import NewsList from "./view/NewDetail/index"
import UserLanding from "./view/UserLanding/index"
import Banner from "./view/Banner/index";
import AccountUser from "./view/AccountUser/index";
import Product from "./view/InsuranceSK/Product/index";
import Login from "./view/login";
import Home from "./view/home";
import Feedback from "./view/Feedback/index";
import Service from "./view/Service/index";
import Question from "./view/InsuranceSK/Question/index";
import QuestionService from "./view/QuestionService/index";
import ServiceDetail from "./view/ServiceDetail/index";
import Contact from "./view/Contact/index";
import HomeSeo from "./view/HomeSeo/index";
import ContactSeo from "./view/ContactSeo/index";
import Condition from "./view/InsuranceSK/Condition/index";
import CategoryCondition from "./view/InsuranceSK/CategoryCondition/index";
import Attribute from "./view/InsuranceSK/Attributes/index";
import ValueAttribute from "./view/InsuranceSK/ValueAttribute/index";
import Item from "./view/InsuranceSK/Item/index";
import Gift from "./view/Gift/index";
import InsuranceProduct from "./view/InsuranceSK/InsuranceProduct/index";
import Team from "./view/Teams/index";
import Document from "./view/InsuranceSK/Document/index";
import DocumentDN from "./view/InsuranceDN/DocumentDN/index";
import InsuranceProductDN from "./view/InsuranceDN/InsuranceProductDN/index";
import QuestionDN from "./view/InsuranceDN/QuestionDN/index";
import InsuranceDNBaoViet from "./view/InsuranceDN/InsurancequoteformBV/index";
import InsuranceDNAnPhat from "./view/InsuranceDN/InsurancequoteformAPhat/index";
import InsuranceDNSK from "./view/InsuranceSK/InsurancequoteformSK/index";
import DocumentDL from "./view/InsuranceDL/DocumentDL/index";
import InsuranceProductDL from "./view/InsuranceDL/InsuranceProductDL/index";
import QuestionDL from "./view/InsuranceDL/QuestionDL/index";
import DocumentOTO from "./view/InsuranceOTO/DocumentOTO/index";
import InsuranceProductOTO from "./view/InsuranceOTO/InsuranceProductOTO/index";
import QuestionOTO from "./view/InsuranceOTO/QuestionOTO/index";

const routes = [{
        path: "/",
        name: "Home",
        component: Home,

    },
    {
        path: "/homeseo",
        name: "homeseo",
        component: HomeSeo,
    },
    {
        path: "/team",
        name: "team",
        component: Team,
    },
    {
        path: "/contactseo",
        name: "contactseo",
        component: ContactSeo,
    },
    {
        path: "/insuranceProduct",
        name: "insuranceProduct",
        component: InsuranceProduct,
    },
    {
        path: "/condition",
        name: "condition",
        component: Condition,
    },
    {
        path: "/catecondition",
        name: "catecondition",
        component: CategoryCondition,
    },
    
    {
        path: "/attribute",
        name: "attribute",
        component: Attribute,
    },
    {
        path: "/valueattribute",
        name: "valueattribute",
        component: ValueAttribute,
    },
    {
        path: "/item",
        name: "item",
        component: Item,
    },
    {
        path: "/account-manager",
        name: "account",
        component: AccountAdmin,
    },
    {
        path: "/categoryProduct",
        name: "category",
        component: Category,
    },
    {
        path: "/gift",
        name: "gift",
        component: Gift,
    },
    {
        path: "/categoryNews",
        name: "categoryNews",
        component: CategoryNews,
    },
    {
        path: "/service",
        name: "service",
        component: Service,
    },
    {
        path: "/questionservice",
        name: "questionservice",
        component: QuestionService,
    },
    {
        path: "/userLanding",
        name: "userLanding",
        component: UserLanding,
    },
    {
        path: "/policy",
        name: "policy",
        component: CategoryPolicy,
    },
    {
        path: "/newslist",
        name: "newslist",
        component: NewsList,
    },
    {
        path: "/policylist",
        name: "policylist",
        component: PolicyList,
    },
    {
        path: "/banner",
        name: "banner",
        component: Banner,
    },
    {
        path: "/question",
        name: "question",
        component: Question,
    },
    {
        path: "/servicedetail",
        name: "servicedetail",
        component: ServiceDetail,
    },
    {
        path: "/contact",
        name: "contact",
        component: Contact,
    },
    {
        path: "/account_user",
        name: "account_user",
        component: AccountUser,
    },
    {
        path: "/login",
        name: "login",
        component: Login,
    },
    {
        path: "/product-manager",
        name: "product",
        component: Product,
    },
    {
        path: "/feedback",
        name: "feedback",
        component: Feedback,
    }
    ,
    {
        path: "/document",
        name: "document",
        component: Document,
    }
    ,
    {
        path: "/documentDN",
        name: "documentDN",
        component: DocumentDN,
    }
    ,
    {
        path: "/insuranceProductDN",
        name: "insuranceProductDN",
        component: InsuranceProductDN,
    },
    {
        path: "/questionDN",
        name: "questionDN",
        component: QuestionDN,
    },
    {
        path: "/insuranceBaoViet",
        name: "insuranceBaoViet",
        component: InsuranceDNBaoViet,
    },
    {
        path: "/insuranceAnPhat",
        name: "insuranceAnPhat",
        component: InsuranceDNAnPhat,
    },
    {
        path: "/insuranceSK",
        name: "insuranceSK",
        component: InsuranceDNSK,
    },
    {
        path: "/insuranceDL",
        name: "insuranceDL",
        component: InsuranceProductDL,
    }
    ,
    {
        path: "/questionDL",
        name: "questionDL",
        component: QuestionDL,
    }
    ,
    {
        path: "/documentDL",
        name: "documentDL",
        component: DocumentDL,
    },
    {
        path: "/insuranceOTO",
        name: "insuranceOTO",
        component: InsuranceProductOTO,
    }
    ,
    {
        path: "/questionOTO",
        name: "questionOTO",
        component: QuestionOTO,
    }
    ,
    {
        path: "/documentOTO",
        name: "documentOTO",
        component: DocumentOTO,
    }
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ["/login"];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = sessionStorage.getItem("user");

    if (authRequired && !loggedIn) {
        return next({
            path: "/login",
            query: { returnUrl: to.path }
        });
    }
    next();
});
// router.beforeEach((to, from, next) => {
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//         if (store.getters.isLoggedIn) {
//             next()
//             return
//         }
//         next('/')
//     } else {
//         next()
//     }
// })
export default router;