<template>
<div class="card card-success mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Thêm mới điều kiện</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitForm">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="hPassword" class="col-form-label">Quyền lợi mở rộng</label>
                            <div class="">
                                <select class="form-control basic" name="" id="categoryConditionId" v-model="condition.categoryConditionId">
                                    <option value="">Chọn</option>
                                    <option v-for="item in catecondition" :key="item.id" :selected="condition.categoryConditionId === item.id" v-bind:value="item.id">
                                        {{item.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="hPassword" class=" col-form-label">Gói bảo hiểm</label>
                            <div class="">
                                <p>Tìm gói bảo hiểm</p>
                                <input type="text" class="form-control mb-2" v-model="query" placeholder="">

                                <select class="form-control basic" name="" id="itemId" v-model="condition.itemId">
                                    <option value="">Chọn</option>
                                    <option v-for="item in conditionFilte" :key="item.id" :selected="condition.itemId === item.id" v-bind:value="item.id">
                                        {{item.productName+ ' '+ item.insuranceName+ ' ' + item.valueAttributeName }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group ">
                            <label for="hPassword" class=" col-form-label">Giá</label>
                            <div class="">
                                <input type="text" class="form-control" id="price" placeholder="" v-model="condition.price" :class="{ error: priceError.status, success: priceSuccess.status }">
                                <p class="text-error" v-if="priceError.status">{{ priceError.text }}</p>
                                <p class="success-text" v-if="priceSuccess.status">{{ priceSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="col-md-12">
                            <div class="card-body" v-for="(items, i) in item" :key="i" style="box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);">
                                <p>Quyền lợi mở rộng: {{i+1}}</p>
                                <p>Giá: {{ items.price }}</p>
                                <button type="button" class="btn btn-danger" @click="removeItem(i)">Xóa</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="button" class="btn btn-info mt-3" @click="addItem">Thêm tạm thời</button>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Thêm mới</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import CategoryConditionService from '@/services/CategoryConditionService';
import ConditionService from '@/services/ConditionService';
import ItemService from '@/services/ItemService';
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    data() {
        return {
            message: "",
            currentImage: undefined,
            a: null,
            url: null,
            item: [],
            ID: null,
            news: null,
            query: "",
            catecondition: null,
            condition: {
                id: null,
                insuranceName: "",
                itemId: "",
                categoryConditionId: "",
                price: "",
                status: true
            },

            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            },
            priceError: {
                text: "",
                status: false,
            },
            priceSuccess: {
                text: "",
                status: false,
            }
        }

    },
    mounted() {
        ConditionService.getAll().then((res) => {
            this.a = res.data
        })
        ItemService.getAll()
            .then((res) => {
                this.news = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
        CategoryConditionService.getAll()
            .then((res) => {
                this.catecondition = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            });
    },
    computed: {
        conditionFilte() {
            if (this.query) {
                return this.news.filter((condition) => {
                    return (
                        (condition.productName + ' ' + condition.insuranceName + ' ' + condition.valueAttributeName)
                        .toLowerCase()
                        .indexOf(this.query.toLowerCase()) != -1

                    )
                })
            } else {
                return this.news;
            }
        },
    },
    methods: {
        addItem() {
            if (this.condition.price == '') {
                createToast({
                    title: 'Bạn không đc để trống',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            } else {
                var itemSame;
                var categoryConditionSame;
                for (let i = 0; i < this.a.length; i++) {
                    const element = this.a[i];
                    if (this.condition.itemId == element.itemId && this.condition.categoryConditionId == element.categoryConditionId) {
                        itemSame = element.itemId;
                        categoryConditionSame = element.categoryConditionId;
                        break;
                    }
                }
                if (this.condition.itemId == itemSame && this.condition.categoryConditionId == categoryConditionSame) {
                    createToast({
                        title: 'Đã có quyền lợi này trong danh sách',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                } else {
                    this.item.push({
                        ...this.condition
                    });
                }
                console.log(this.item)
            }
        },
        removeItem(i) {
            this.item.splice(i, 1);
        },
        onSubmitForm() {

            if (this.condition.price.length == 0) {
                this.priceError = {
                    text: "price cannot be empty!",
                    status: true
                }
                this.priceSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.condition.price.length > 0) {
                this.priceSuccess = {
                    text: "Success!",
                    status: true
                }
                this.priceError = {
                    text: "",
                    status: false
                }
            } else {
                this.priceSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.priceSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    if (this.item.length > 1) {
                        for (let i = 0; i < this.item.length; i++) {
                            const element = this.item[i];
                            ConditionService.create(element)
                                .then((res) => {
                                    this.ID = res.data.id;
                                    this.condition.id = this.ID;
                                    this.condition.status = true;

                                    this.$emit("ShowData", this.condition);
                                    createToast({
                                        title: 'Thêm mới thành công',
                                    }, {
                                        position: 'top-right',
                                        type: 'success',
                                        transition: 'zoom',
                                        showIcon: 'true',
                                        showCloseButton: 'false',
                                    })
                                })
                                .catch((error) => {
                                    // error.response.status Check status code
                                    console.log(error);
                                    createToast({
                                        title: 'Đã có lỗi!',
                                    }, {
                                        position: 'top-right',
                                        type: 'danger',
                                        transition: 'zoom',
                                        showIcon: 'true',
                                        showCloseButton: 'false',
                                    })
                                })
                                .finally(() => {
                                    //Perform action in always
                                });
                        }

                    } else {
                        ConditionService.create(this.condition)
                            .then((res) => {
                                //Perform Success Action
                                this.ID = res.data.id;
                                this.condition.id = this.ID;
                                this.condition.status = true;

                                this.$emit("ShowData", this.condition);
                                createToast({
                                    title: 'Thêm mới thành công',
                                }, {
                                    position: 'top-right',
                                    type: 'success',
                                    transition: 'zoom',
                                    showIcon: 'true',
                                    showCloseButton: 'false',
                                })
                            })
                            .catch((error) => {
                                // error.response.status Check status code
                                console.log(error);
                                createToast({
                                    title: 'Đã có lỗi!',
                                }, {
                                    position: 'top-right',
                                    type: 'danger',
                                    transition: 'zoom',
                                    showIcon: 'true',
                                    showCloseButton: 'false',
                                })
                            })
                            .finally(() => {
                                //Perform action in always
                            });

                    }

                } else {
                    createToast({
                        title: 'Bạn không có quyền sử dụng chức năng này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Thêm mới thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }

        },
    }

}
</script>
