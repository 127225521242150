import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class InsurancequoteformSKService {
    
    getAll() {
        return http.get("/QuoteInformationSKs",httpOptions);
    }
    get(id) {
        return http.get(`/QuoteInformationSKs/${id}`,httpOptions);
    }
    create(data) {
        return http.post("/QuoteInformationSKs", data,httpOptions);
    }
    update(data) {
        return http.post(`/QuoteInformationSKs/PutQuoteInformationSKs`, data,httpOptions);
    }
    // updatedate(data) {
    //     return http.post(`/QuoteInformationSKs/QuoteInformationSKsHangfire`, data,httpOptions);
    // }
    delete(id) {
            return http.delete(`/QuoteInformationSKs/${id}`,httpOptions);
        }
        //   deleteAll() {
        //     return http.delete(`/tutorials`);
        //   }
        //   findByTitle(title) {
        //     return http.get(`/tutorials?title=${title}`);
        //   }
}
export default new InsurancequoteformSKService();