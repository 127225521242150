<template>
<div class="card card-warning mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Chỉnh sửa tài liệu</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" enctype="multipart/form-data" v-on:submit.prevent="onSubmitEditForm" v-if="documents">
                <input type="hidden" v-model="documents.id" />
                <div class="row">
                    <div class="col-md-5">
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Tên :</label>
                            <div class="col-xl-10 col-lg-10 col-sm-10">
                                <input type="text" class="form-control" id="name" placeholder="" v-model="documents.name" :class="{ error: nameError.status, success: nameSuccess.status }" />
                                <p class="text-danger mb-0 mt-2" v-if="nameError.status">
                                    {{ nameError.text }}
                                </p>
                                <p class="text-success mb-0 mt-2" v-if="nameSuccess.status">
                                    {{ nameSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Tài liệu:</label>
                            <div class="col-8">
                                <label class="btn btn-default p-0">
                                    <input type="file" ref="file" name="file" multiple="multiple" @change="selectFile($event)" />
                                </label>
                                <label for="">Tài liệu: </label>
                                <input type="text" name="text" disabled :value="documents.file" />
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Danh mục</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <select class="form-control basic" name="" id="insuranceProductDNId" v-model="documents.insuranceProductDNId">
                                    <option value="">Chọn</option>
                                    <option v-for="item in categorys" :key="item.id" :selected="documents.insuranceProductDNId === item.id" v-bind:value="item.id">{{
                                                        item.name
                                                    }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Xác nhận sửa</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import DocumentDNService from "@/services/DocumentDNService";
import InsuranceProductDNService from '@/services/InsuranceProductDNService';
import UploadService from "@/services/UploadService";
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    props: ["document"],
    data() {
        return {
            message: "",
            url: null,
            documents: this.document,
            ishowImage: false,
            categorys: null,
            currentFile: null,
            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            }
        };
    },
    mounted() {
        InsuranceProductDNService.getAll()
            .then((res) => {
                this.categorys = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
    },
    methods: {
        selectFile(ev) {
            this.currentFile = ev.target.files[0];
            this.documents.file = ev.target.files[0].name;
        },
        onSubmitEditForm() {
            if (this.documents.name.length == 0) {
                this.nameError = {
                    text: "*Tên không được để trống!",
                    status: true,
                };
                this.nameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.documents.name.length > 0) {
                this.nameSuccess = {
                    text: "Thành công!",
                    status: true,
                };
                this.nameError = {
                    text: "",
                    status: false,
                };
            } else {
                this.nameError = {
                    text: "",
                    status: false,
                };
            }

            if (this.nameSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    UploadService.upload(this.currentFile)
                        .then((response) => {
                            console.log();
                            this.message = response.data.message;
                        })
                        .catch((err) => {
                            this.message = "Unable to load File  ! " + err;
                            this.currentFile = undefined;
                        });
                    DocumentDNService.update(this.documents)
                        .then((res) => {
                            //Perform Success Action
                            this.documents = res.data;
                            this.documents.productName = res.data.productName;
                            this.$emit("ShowEditData", this.documents);
                            createToast({
                                title: 'Chỉnh sửa thành công',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                            createToast({
                                title: 'Đã xảy ra lỗi',
                            }, {
                                position: 'top-right',
                                type: 'danger',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .finally(() => {
                            //Perform action in always
                        });
                    this.$emit("ShowEditData", this.documents);

                } else {
                    createToast({
                        title: 'Bạn không có quyền sử dụng chức năng này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Chỉnh sửa thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },

    },
};
</script>
