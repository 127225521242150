<template>
<div class="card card-success mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Thêm mới giá theo quyền lợi chính</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitForm">
                <div class="row">
                    <div class="col-md-6">
                        <div class="col-lg-12" style="padding: 0;">
                            <div class="form-group">
                                <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Gói bảo hiểm</label>
                                <div class="col-xl-12 col-lg-12 col-sm-12">
                                    <p>Tìm gói bảo hiểm</p>
                                    <input type="text" class="form-control mb-2" v-model="query" placeholder="">
                                    <select class="form-control basic" name="" id="productId" v-model="item.productId">
                                        <option value="">Chọn</option>
                                        <option v-for="items in itemFilte" :key="items.id" :selected="item.productId === items.id" v-bind:value="items.id">{{
                                                        items.name + ' '+ items.insuranceName
                                                    }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" style="padding: 0;">
                            <div class="form-group">
                                <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Danh mục ĐTBH</label>
                                <div class="col-xl-12 col-lg-12 col-sm-12">
                                    <select class="form-control basic" name="" id="attributeId" v-model="item.attributeId" @change="changeCity($event)">
                                        <option value="">Chọn</option>
                                        <option v-for="items in attrbutes" :key="items.id" v-bind:value="items.id">{{
                                                        items.name
                                                    }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" style="padding: 0;">
                            <div class="form-group">
                                <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Danh sách ĐTBH</label>
                                <div class="col-xl-12 col-lg-12 col-sm-12">
                                    <p>Tìm độ tuổi</p>
                                    <input type="text" class="form-control mb-2" v-model="query1" placeholder="">
                                    <select class="form-control basic" name="" id="valueAttributeId" v-model="item.valueAttributeId">
                                        <option value="">Chọn</option>
                                        <option v-for="items in itemFilte1" :key="items.id" v-bind:value="items.id">{{
                                                       items.code + ' ' + items.name
                                                    }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="form-group">
                                <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Giá</label>
                                <div class="col-xl-12 col-lg-12 col-sm-12">
                                    <input type="number" class="form-control" id="price" placeholder="" v-model="item.price" :class="{ error: priceError.status, success: priceSuccess.status }">
                                    <p class="text-error" v-if="priceError.status">{{ priceError.text }}</p>
                                    <p class="success-text" v-if="priceSuccess.status">{{ priceSuccess.text }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-10">
                                <button type="button" class="btn btn-info mt-3" @click="addItem">Thêm tạm thời</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="col-lg-6">
                            <div class="col-md-12">
                                <div class="card-body" v-for="(itemsz, i) in items" :key="i" style="box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);">
                                    <p>Quyền lợi chính: {{i+1}}</p>
                                    <p>Giá: {{ itemsz.price }}</p>
                                    <button type="button" class="btn btn-danger" @click="removeItem(i)">Xóa</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Thêm mới</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import ItemService from '@/services/ItemService';
import ProductService from '@/services/ProductService';
import AttributeService from '@/services/AttributeService';
import ValueAttributeService from '@/services/ValueAttributeService';
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    data() {
        return {
            message: "",
            currentImage: undefined,
            a: null,
            query: "",
            query1: "",
            products: null,
            items: [],
            attrbutes: null,
            valueattributes: null,
            proCate: null,
            url: null,
            ID: null,
            item: {
                id: null,
                price: '',
                productId: "",
                attributeId: "",
                valueAttributeId: "",
                status: true
            },
            priceError: {
                text: "",
                status: false,
            },
            priceSuccess: {
                text: "",
                status: false,
            },
        }

    },
    mounted() {
        ItemService.getAll().then((res) => {
            this.a = res.data
        })
        ProductService.getAll()
            .then((res) => {
                this.products = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
        AttributeService.getAll()
            .then((res) => {
                this.attrbutes = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
        ValueAttributeService.getAll()
            .then((res) => {
                this.valueattributes = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })

    },
    computed: {
        itemFilte() {
            if (this.query) {
                return this.products.filter((pro) => {
                    return (
                        (pro.name + ' ' + pro.categoryName)
                        .toLowerCase()
                        .indexOf(this.query.toLowerCase()) != -1

                    )
                })
            } else {
                return this.products;
            }
        },
        itemFilte1() {
            if (this.query1) {
                return this.valueattributes.filter((pro) => {
                    return (
                        (pro.code + ' ' + pro.name)
                        .toLowerCase()
                        .indexOf(this.query1.toLowerCase()) != -1
                    )
                })
            } else {
               
                return this.valueattributes;
            }
            
        },
    },
    methods: {
        addItem() {
            if (this.item.price == '') {
                createToast({
                    title: 'Bạn không đc để trống',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            } else {
                var productSame;
                var attributeSame;
                var valueAttributeSame;
                for (let i = 0; i < this.a.length; i++) {
                    const element = this.a[i];
                    if (this.item.productId == element.productId && this.item.attributeId == element.attributeId && this.item.valueAttributeId == element.valueAttributeId) {
                        productSame = element.productId;
                        attributeSame = element.attributeId;
                        valueAttributeSame = element.valueAttributeId;
                        break;
                    }
                }
                if (this.item.productId == productSame && this.item.attributeId == attributeSame && this.item.valueAttributeId == valueAttributeSame) {
                    createToast({
                        title: 'Đã có quyền lợi này trong danh sách',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                } else {
                    this.items.push({
                        ...this.item
                    });
                }
                console.log(this.items)
            }
        },
        removeItem(i) {
            this.items.splice(i, 1);
        },
        oncoutry(id) {
            ValueAttributeService.getAttribute(id).then((res) => {
                this.proCate = res.data
            })
        },
        changeCity(event) {
            const pro = event.target.value;
            this.oncoutry(pro);
        },
        onSubmitForm() {
            if (this.item.price.length == 0) {
                this.priceError = {
                    text: "Giá không được để trống",
                    status: true
                };
                this.priceSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.item.price.length > 0) {
                this.priceSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.priceError = {
                    text: "",
                    status: false
                }
            } else {
                this.priceSuccess = {
                    text: "",
                    status: true
                }
            }
            let login = JSON.parse(sessionStorage.getItem("user"));
            if (login.role == 1 || login.role == 2) {
                if (this.priceSuccess.status == true) {
                    if (this.items.length > 1) {
                        for (let i = 0; i < this.items.length; i++) {
                            const element = this.items[i];
                            ItemService.create(element)
                                .then((res) => {
                                    this.ID = res.data.id;
                                    this.item.id = this.ID;
                                    this.item.status = true;
                                    this.$emit("ShowData", this.item);
                                    createToast({
                                        title: 'Thêm mới thành công',
                                    }, {
                                        position: 'top-right',
                                        type: 'success',
                                        transition: 'zoom',
                                        showIcon: 'true',
                                        showCloseButton: 'false',
                                    })
                                })
                                .catch((error) => {
                                    // error.response.status Check status code
                                    console.log(error);
                                    createToast({
                                        title: 'Đã xảy ra lỗi',
                                    }, {
                                        position: 'top-right',
                                        type: 'danger',
                                        transition: 'zoom',
                                        showIcon: 'true',
                                        showCloseButton: 'false',
                                    })
                                })
                                .finally(() => {
                                    //Perform action in always
                                });
                        }
                    } else {
                        ItemService.create(this.item)
                            .then((res) => {
                                //Perform Success Action
                                this.ID = res.data.id;
                                this.item.id = this.ID;
                                this.item.status = true;
                                this.$emit("ShowData", this.item);
                                createToast({
                                    title: 'Thêm mới thành công',
                                }, {
                                    position: 'top-right',
                                    type: 'success',
                                    transition: 'zoom',
                                    showIcon: 'true',
                                    showCloseButton: 'false',
                                })
                            })
                            .catch((error) => {
                                // error.response.status Check status code
                                console.log(error);
                                createToast({
                                    title: 'Đã xảy ra lỗi',
                                }, {
                                    position: 'top-right',
                                    type: 'danger',
                                    transition: 'zoom',
                                    showIcon: 'true',
                                    showCloseButton: 'false',
                                })
                            })
                            .finally(() => {
                                //Perform action in always
                            });

                    }
                } else {
                    createToast({
                        title: 'Thêm mới thất bại',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                alert("Bạn không có quyền sử dụng chức năng này");
                createToast({
                    title: 'Bạn không có quyền này',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }

        },
    }

}
</script>
