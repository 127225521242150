<template>
<body class="login-page" style="min-height: 464px;">
    <div class="login-box">

        <div class="card card-outline card-primary">
            <div class="card-header text-center" style="background-color: #f05c22;">
                <a href="../../index2.html" class="h1"><b>Admin</b>Insurance</a>
            </div>
            <div class="card-body">
                <p class="login-box-msg">Đăng nhập để bắt đầu quản lý</p>
                <p class="text-error" v-if="LogginFalse.status" style="color:red">{{ LogginFalse.text }}</p>
                <form action="" method="post" v-on:submit.prevent="Submit">
                    <div class="input-group mb-3">
                        <input id="username" name="username" type="text" class="form-control" placeholder="Email" v-model="user.email" :class="{ error: emailError.status, success: emailSuccess.status }">

                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>
                    <p class="text-error" style="color: red;" v-if="emailError.status"><i>* {{ emailError.text }}</i></p>
                    <p class="success-text" v-if="emailSuccess.status">{{ emailSuccess.text }}
                    </p>
                    <div class="input-group mb-3">
                        <input id="password" name="password" type="password" class="form-control" placeholder="Mật khẩu" v-model="user.password" :class="{ error: passwordError.status, success: passwordSuccess.status }">

                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <p class="text-error" style="color: red;" v-if="passwordError.status"><i>* {{ passwordError.text }}</i></p>
                    <p class="success-text" v-if="passwordSuccess.status">{{ passwordSuccess.text }}
                    </p>
                    
                    <div class="row">

                        <div class="col-6">
                            <button type="submit" class="btn btn-primary btn-block">Đăng nhập</button>
                        </div>
                        <div class="col-6">
                            <p class="mb-1">
                                <a href="forgot-password.html">I forgot my password</a>
                            </p>
                        </div>
                    </div>
                </form>

            </div>

        </div>

    </div>

</body>
</template>

<script>
import axios from 'axios'

import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    data() {
        return {
            user: {
                email: "",
                password: "",
                accountName: "",
            },
            emailError: {
                text: "",
                status: false,
            },
            emailSuccess: {
                text: "",
                status: false,
            },
            passwordError: {
                text: "",
                status: false,
            },
            passwordSuccess: {
                text: "",
                status: false,
            },
            accountNameError: {
                text: "",
                status: false,
            },
            accountNameSuccess: {
                text: "",
                status: false,
            },
            LogginFalse: {
                text: "",
                status: false,
            }

        }
    },
    methods: {
        Submit(event) {
            const regex = /^\w+([.-]?\w+)*@[a-z]+([.-]?\w+)*(.\w{2,3})+$/;
            if (this.user.email.length == 0) {
                this.emailError = {
                    text: "Email không được để trống!",
                    status: true,
                };
            } else if (!regex.test(this.user.email)) {
                this.emailError = {
                    text: "Email sai định dạng!",
                    status: true,
                };
            } else if (regex.test(this.user.email)) {
                this.emailSuccess = {
                    text: "",
                    status: true,
                };
                this.emailError = {
                    text: "",
                    status: false,
                };
            } else {
                this.emailError = {
                    text: "",
                    status: true,
                };
            }
            if (this.user.password.length == 0) {
                this.passwordError = {
                    text: "Mật khẩu không được để trống!",
                    status: true
                }

            } else if (this.user.password.length > 0) {
                this.passwordSuccess = {
                    text: "",
                    status: true,
                };
                this.passwordError = {
                    text: "",
                    status: false,
                };
            } else {
                this.passwordError = {
                    text: "",
                    status: false
                } 
            } 
            
            if (this.emailSuccess.status == true && this.passwordSuccess.status == true) {
                event.preventDefault()
                axios.post('https://vietlife.com.vn/InsuranceApi/api/accountadmin/login/', this.user)
                    .then(response => {

                        const token = response.data.token
                        const users = response.data.user
                        // if (this.user.email == users.email && this.user.password == users.password && this.user.accountName == users.accountName) 
                        if (this.user.email == users.email && this.user.password == users.password) { 
                            createToast({
                                title: 'Đăng nhập thành công',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom', 
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                            sessionStorage.setItem('token', token)
                            sessionStorage.setItem('user', JSON.stringify(users))
                            axios.defaults.headers.common['Authorization'] = "Bearer " + token
                            window.location.href = "/";
                        } else {

                            this.LogginFalse = {
                                text: "Email, mật khẩu hoặc tên đăng nhập không đúng",
                                status: true
                            }
                        }

                    }).catch(err => {
                        sessionStorage.removeItem('token');
                        console.log(err);
                        this.LogginFalse = {
                            text: "Email, mật khẩu hoặc tên đăng nhập không đúng",
                            status: true
                        }
                    })
            } else {
                createToast({
                    title: 'Đăng nhập thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
            
        }
    }

}
</script>
